import { useThree } from '@react-three/fiber'
import redBackground from '../../../../img/background/BackgroundRed.png'
import yellowBackground from '../../../../img/background/BackgroundYellow.png'
import greenBackground from '../../../../img/background/BackgroundGreen.png'
import * as THREE from 'three'



function ExtremumPlate(positionPlate) {
    /* Camera const */
    let positionCamera = {x: 0, y: 8 - 30 * positionPlate, z: 25};
    let rotationCamera = {_x: -0.15, _y: 0, _z: 0};
    /* Camera variables */
    
    let fovCamera = 50 * (Math.PI / 180) * (1/2);
    

    
    /* MainF */
    let z = -1

    let dY = Math.abs((positionCamera.z - z) * Math.tan(rotationCamera._x)) 
    let L = Math.abs(dY / Math.sin(rotationCamera._x))

    let constTg = Math.abs(L * Math.tan(fovCamera)) * 2
    let stepY = Math.cos(rotationCamera._x) * constTg
    let x = 0
    let y =  positionCamera.y - dY;
    return [x, y, z, constTg]
}

function PlateBackground() {
    const redTexture =  new THREE.TextureLoader().load(redBackground);
    const yellowTexture=  new THREE.TextureLoader().load(yellowBackground);
    const greenTexture =  new THREE.TextureLoader().load(greenBackground);
    const optionPlate1 = ExtremumPlate(1)
    const optionPlate2 = ExtremumPlate(2)
    const optionPlate3 = ExtremumPlate(3)
    const optionPlate4 = ExtremumPlate(4)
    const {scene, camera} = useThree();
    
    return (<>
        <PlateTasteBackground mainOption={optionPlate1} camera={camera} texture={redTexture} position={0}/>
        <PlateTasteBackground mainOption={optionPlate2} camera={camera} texture={yellowTexture} position={1}/>
        <PlateTasteBackground mainOption={optionPlate3} camera={camera} texture={greenTexture} position={2}/>
        <PlateTasteBackground mainOption={optionPlate4} camera={camera} texture={redTexture} position={3}/>
        <PlateTasteBackground mainOption={optionPlate4} camera={camera} texture={yellowTexture} position={4}/>
        <PlateTasteBackground mainOption={optionPlate4} camera={camera} texture={greenTexture} position={5}/>
    </>);
}
//scene.getObjectByName('groupTasteBackground1').children[0].material.opacity = 1
function PlateTasteBackground({mainOption, camera, texture, position}) {
    return(
        <group position={[mainOption[0], mainOption[1], mainOption[2],]} rotation={[-0.15, 0, 0]} name={'groupTasteBackground' + position}>
            <mesh position={[0, -mainOption[3] * (1/3), 0,]}>
                <planeGeometry args={[mainOption[3] * camera.aspect, mainOption[3]/3]} />
                <meshBasicMaterial attach="material" map={texture} toneMapped={false} opacity={0} transparent />
            </mesh>
            <mesh position={[0, mainOption[3] * (1/6), 0,]}  >
                <planeGeometry args={[mainOption[3] * camera.aspect, mainOption[3]*( 2/3)]} />
                <meshBasicMaterial attach="material" toneMapped={false} color="white" opacity={0} transparent/>
            </mesh>
        </group>
    )
}

export default PlateBackground;