import '../../../css/Taste.css'
import tasteLeftUp1 from '../../../img/taste/leftTaste1.png'
import tasteLeftUp2 from '../../../img/taste/leftTaste2.png'
import tasteLeftUp3 from '../../../img/taste/leftTaste3.png'
import tasteLeftUp4 from '../../../img/taste/leftTaste4.png'

import tasteLeftDown1 from '../../../img/taste/leftRed1EN.png'
import tasteLeftDown2 from '../../../img/taste/leftRed2EN.png'
import tasteLeftDown3 from '../../../img/taste/leftRed3EN.png'
import tasteLeftDown4 from '../../../img/taste/leftRed4EN.png'
/* Right */
import tasteRightCost1 from '../../../img/taste/right/nutritional Value1 EN.png'
import tasteRightCost2 from '../../../img/taste/right/nutritional Value2 EN.png'
import tasteRightCost3 from '../../../img/taste/right/nutritional Value3 EN.png'
import tasteRightCost4 from '../../../img/taste/right/nutritional Value4 EN.png'

import tasteRightVit1 from '../../../img/taste/right/vitamins1.png'
import tasteRightVit2 from '../../../img/taste/right/vitamins2.png'
import tasteRightVit3 from '../../../img/taste/right/vitamins3.png'
import tasteRightVit4 from '../../../img/taste/right/vitamins4.png'
import tasteRightVit5 from '../../../img/taste/right/vitamins5.png'
import tasteRightVit6 from '../../../img/taste/right/vitamins6.png'
import tasteRightVit7 from '../../../img/taste/right/vitamins7.png'
import tasteRightVit8 from '../../../img/taste/right/vitamins8.png'
import tasteRightVit9 from '../../../img/taste/right/vitamins9.png'
import tasteRightVit10 from '../../../img/taste/right/vitamins10.png'

import cross from '../../../img/logo/closeTaste.png'

function TasteBase({langImg, langText, color, swiper}) {
    return (
        <div className="taste invisibleDiv">
            <div className='left'>
                <h6 className={color}>
                    {langText[0][0]}<br/>{langText[0][1]}
                </h6>
                <p>
                    {langText[0][2]}
                </p>
                <div>
                    <img src={tasteLeftUp1} alt="" />
                    <img src={tasteLeftUp2} alt="" />
                    <img src={tasteLeftUp3} alt="" />
                    <img src={tasteLeftUp4} alt="" />

                    <img src={langImg[0][0]} alt="" />
                    <img src={langImg[0][1]} alt="" />
                    <img src={langImg[0][2]} alt="" />
                    <img src={langImg[0][3]} alt="" />
                </div>
            </div>
            <div className='right'>
                <h6 className={color}>{langText[1][0]}<br/>{langText[1][1]}</h6>
                <p className={color}>{langText[1][3]}</p>
                <div className='boxImg upper'>
                    <img src={langImg[1][0]} alt="" />
                    <img src={langImg[1][1]} alt="" />
                    <img src={langImg[1][2]} alt="" />
                    <img src={langImg[1][3]} alt="" />
                </div>
                <h6 className={'lowerH6' + ' ' + color}>{langText[1][2]}</h6>
                <p className={color}>{langText[1][3]}</p>
                <div className='boxImg lower'>
                    <div>
                        <img src={tasteRightVit1} alt="" />
                        <p>6.25 mg / 41.6%</p>
                    </div>
                    <div>
                        <img src={tasteRightVit2} alt="" />
                        <p>1.04 mg / 62.8%</p>
                    </div>
                    <div>
                        <img src={tasteRightVit3} alt="" />
                        <p>1.19 mg / 60.6%</p>
                    </div>
                    <div>
                        <img src={tasteRightVit4} alt="" />
                        <p>11.1 mg / 56.8%</p>
                    </div>
                    <div>
                        <img src={tasteRightVit5} alt="" />
                        <p>6.42 mg / 128%</p>
                    </div>

                    <div>
                        <img src={tasteRightVit6} alt="" />
                        <p>1.36 mg / 68.1%</p>
                    </div>
                    <div>
                        <img src={tasteRightVit7} alt="" />
                        <p>94.6 mcg / 187%</p>
                    </div>
                    <div>
                        <img src={tasteRightVit8} alt="" />
                        <p>250 mcg / 62.8%</p>
                    </div>
                    <div>
                        <img src={tasteRightVit9} alt="" />
                        <p>0.75 mcg / 25%</p>
                    </div>
                    <div>
                        <img src={tasteRightVit10} alt="" />
                        <p>37.8 mg / 53%</p>
                    </div>
                </div>
            </div>
            <img src={langImg[2]} alt="" className='centerSpin'/>
            <img src={cross} alt="" className='crossTaste'  onClick={() => swiper[0]('taste', null, swiper[1])}/>
        </div>
    );
}

export default TasteBase;