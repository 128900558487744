import TasteBase from "./TasteBase";

function Taste({lang, swiper}) {
    return (
        <>
            <TasteBase langImg={[lang.img[1], lang.img[3], lang.img[4]]} langText={[lang.text[2], lang.text[3]]} color={'red'} swiper={swiper}/>
            <TasteBase langImg={[lang.img[2], lang.img[3], lang.img[5]]} langText={[lang.text[1], lang.text[3]]} color={'yellow'} swiper={swiper}/>
            <TasteBase langImg={[lang.img[0], lang.img[3], lang.img[6]]} langText={[lang.text[0], lang.text[3]]} color={'green'} swiper={swiper}/>
        </>
    );
}

export default Taste;