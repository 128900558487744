import au from '../img/country/Austria.png'
import gr from '../img/country/Germany.png'
import sw from '../img/country/Switzerland.png'
import uae from '../img/country/Emirates.png'
import om from '../img/country/Oman.png'
import br from '../img/country/Bahrain.png'
import qt from '../img/country/Qatar.png'
import ku from '../img/country/Kuwait.png'
import is from '../img/country/Israel.png'

import plate10 from '../img/shop/imgSop1-0.png'
import plate11 from '../img/shop/imgSop1-1.png'
import plate12 from '../img/shop/imgSop1-2.png'
import plate13 from '../img/shop/imgSop1-3.png'
import plate14 from '../img/shop/imgSop1-4.png'
import plate15 from '../img/shop/imgSop1-5.png'

import plate20 from '../img/shop/imgSop2-0.png'
import plate21 from '../img/shop/imgSop2-1.png'
import plate22 from '../img/shop/imgSop2-2.png'
import plate23 from '../img/shop/imgSop2-3.png'
import plate24 from '../img/shop/imgSop2-4.png'
import plate25 from '../img/shop/imgSop2-5.png'

import * as THREE from 'three'

let cntBase = [
    au, 'Austria',
    gr, 'Germany',
    sw, 'Switzerland',
    uae, 'UAE',
    om, 'Oman',
    br, 'Bahrain',
    qt, 'Qatar',
    ku, 'Kuwait',
    is, 'Israel',
]


function makeCountry(countryNum, plate) {
    let countryArr = []
    for (let i = 0; i < cntBase.length; i++) {
        countryArr.push(cntBase[i])
    }
    let pastImg = countryArr[0]
    let pastValue = countryArr[1]
    let presentImg = countryArr[countryNum]
    let presentValue = countryArr[countryNum+1]

    countryArr[0] = presentImg;
    countryArr[1] = presentValue;
    countryArr[countryNum] = pastImg;
    countryArr[countryNum + 1] = pastValue;

    return {
        country: countryArr,
        plate: plate,
    } 
}
let plateAu = [
    new THREE.TextureLoader().load(plate10), new THREE.TextureLoader().load(plate11),
    new THREE.TextureLoader().load(plate12), new THREE.TextureLoader().load(plate13),
    new THREE.TextureLoader().load(plate14), new THREE.TextureLoader().load(plate15),
]
let countryAu = makeCountry(0, plateAu)

let plateGr = [
    new THREE.TextureLoader().load(plate20), new THREE.TextureLoader().load(plate21),
    new THREE.TextureLoader().load(plate22), new THREE.TextureLoader().load(plate23),
    new THREE.TextureLoader().load(plate24), new THREE.TextureLoader().load(plate25),
]
let countryGr = makeCountry(2, plateGr)

let plateSw = [
    new THREE.TextureLoader().load(plate10), new THREE.TextureLoader().load(plate21),
    new THREE.TextureLoader().load(plate22), new THREE.TextureLoader().load(plate13),
    new THREE.TextureLoader().load(plate14), new THREE.TextureLoader().load(plate25),
]
let countrySw = makeCountry(4, plateSw)

let plateUae = [
    new THREE.TextureLoader().load(plate11), new THREE.TextureLoader().load(plate22),
    new THREE.TextureLoader().load(plate23), new THREE.TextureLoader().load(plate13),
    new THREE.TextureLoader().load(plate15), new THREE.TextureLoader().load(plate12),
]
let countryUae = makeCountry(6, plateUae)

let plateOm = [
    new THREE.TextureLoader().load(plate10), new THREE.TextureLoader().load(plate21),
    new THREE.TextureLoader().load(plate22), new THREE.TextureLoader().load(plate13),
    new THREE.TextureLoader().load(plate14), new THREE.TextureLoader().load(plate25),
]
let countryOm = makeCountry(8, plateOm)

let plateBr = [
    new THREE.TextureLoader().load(plate10), new THREE.TextureLoader().load(plate21),
    new THREE.TextureLoader().load(plate22), new THREE.TextureLoader().load(plate13),
    new THREE.TextureLoader().load(plate14), new THREE.TextureLoader().load(plate25),
]
let countryBr = makeCountry(10, plateBr)

let plateQt = [
    new THREE.TextureLoader().load(plate10), new THREE.TextureLoader().load(plate21),
    new THREE.TextureLoader().load(plate22), new THREE.TextureLoader().load(plate13),
    new THREE.TextureLoader().load(plate14), new THREE.TextureLoader().load(plate25),
]
let countryQt = makeCountry(12, plateQt)

let plateKu = [
    new THREE.TextureLoader().load(plate10), new THREE.TextureLoader().load(plate21),
    new THREE.TextureLoader().load(plate22), new THREE.TextureLoader().load(plate13),
    new THREE.TextureLoader().load(plate14), new THREE.TextureLoader().load(plate25),
]
let countryKu = makeCountry(14, plateKu)

let plateIs = [
    new THREE.TextureLoader().load(plate10), new THREE.TextureLoader().load(plate21),
    new THREE.TextureLoader().load(plate22), new THREE.TextureLoader().load(plate13),
    new THREE.TextureLoader().load(plate14), new THREE.TextureLoader().load(plate25),
]
let countryIs = makeCountry(16, plateIs)

let countryArr = [countryAu, countryGr, countrySw, countryUae, countryOm, countryBr, countryQt, countryKu, countryIs]
export {countryArr}