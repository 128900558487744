import Loader  from "./Loader/Loader";
import MainHeader from "./Header/MainHeader";
import Taste from "./Taste/Taste";
import HtmlText from "./ThreeHtml/HtmlText";
import DownSection from "./downSection/DownSection";
import ScrollBar from "./Loader/ScrollBar";

function MainHtml({lang, changeLang, country, setCountry, swiper}) {
    return (
        <>
            <MainHeader lang={lang.navigation} changeLang={changeLang} swiper={swiper}/>
            <DownSection lang={lang.footer} swiper={swiper}/>
            <HtmlText lang={lang.textHtml} country={country} setCountry={setCountry}/>
            <Taste lang={lang.taste} swiper={swiper}/>
            <Loader />
            <ScrollBar />
        </>
    );
}

export default MainHtml;