function makeLang(nav, text, imgTaste, textHtml, footer) {
    return {
        navigation: nav,
        textHtml: text,
        taste: {
            img: imgTaste,
            text: textHtml,
        },
        footer: footer,
    };
};

export default makeLang;