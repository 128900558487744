



import { useGesture } from '@use-gesture/react';

function Pc({logo, lang, changeLang, swiper}) {   
    function switcher() { document.getElementById('moveLang').classList.toggle("closeMenu") }
    return (
        <>
            <img src={logo} alt="" onClick={() => console.log(1)}/>
            <div>
                <nav>
                    <div className="absTest" onClick={() => swiper[0]('navigation', 0, swiper[1])}>{lang[0]}</div>
                    <div className="absTest" onClick={() => swiper[0]('navigation', 4, swiper[1])}>{lang[1]}</div> 
                    <div className="absTest" onClick={() => swiper[0]('navigation', 5, swiper[1])}>{lang[2]}</div> 
                    <div className="absTest" onClick={() => swiper[0]('navigation', 6, swiper[1])}>{lang[3]}</div>                             
                </nav>
                <div id="changeLang">
                    <div id='moveLang'>
                        <div id="hidenLang">
                            <div className="reversBackground">
                                <img src={lang[5]} alt="" onClick={() => {changeLang(lang[5]); document.getElementById('moveLang').classList.toggle("closeMenu")}} className="imgLang reversBackground"/>
                            </div>
                            <div>
                                <img src={lang[6]} alt="" onClick={() => {changeLang(lang[6]); document.getElementById('moveLang').classList.toggle("closeMenu")}} className="imgLang"/>
                            </div>
                        </div>
                    </div>
                    <div id="visLAng">
                        <img src={lang[4]} alt="" onClick={switcher} className="imgLang"/>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Pc;