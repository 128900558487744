import makeLang from "./lang";
// IMG
    // IMG LANG
    import langRu from '../img/lang/ru.png';
    import langEn from '../img/lang/en.png';
    import langDe from '../img/lang/de.png';
    // 360
    import red360 from '../img/deg360/red360.png';
    import yellow360 from '../img/deg360/yellow360.png';
    import green360 from '../img/deg360/green360.png';
    // RU
    import imgTasteRu1 from '../img/taste/ru/left/leftGreenRu1.png';
    import imgTasteRu2 from '../img/taste/ru/left/leftGreenRu2.png';
    import imgTasteRu3 from '../img/taste/ru/left/leftGreenRu3.png';
    import imgTasteRu4 from '../img/taste/ru/left/leftGreenRu4.png';
    import imgTasteRu5 from '../img/taste/ru/left/leftRedRu1.png';
    import imgTasteRu6 from '../img/taste/ru/left/leftRedRu2.png';
    import imgTasteRu7 from '../img/taste/ru/left/leftRedRu3.png';
    import imgTasteRu8 from '../img/taste/ru/left/leftRedRu4.png';
    import imgTasteRu9 from '../img/taste/ru/left/leftYellowRu1.png';
    import imgTasteRu10 from '../img/taste/ru/left/leftYellowRu2.png';
    import imgTasteRu11 from '../img/taste/ru/left/leftYellowRu3.png';
    import imgTasteRu12 from '../img/taste/ru/left/leftYellowRu4.png';
    import imgTasteRu13 from '../img/taste/ru/right/ValueRu1.png';
    import imgTasteRu14 from '../img/taste/ru/right/ValueRu2.png';
    import imgTasteRu15 from '../img/taste/ru/right/ValueRu3.png';
    import imgTasteRu16 from '../img/taste/ru/right/ValueRu4.png';
////////////////////////////////////////////////////////////////////////
let navRu = ["ГЛАВНАЯ", "ВКУСЫ", "ГДЕ КУПИТЬ", "КОНТАКТЫ", langRu, langEn, langDe];
let textRu = [
    "Future Wellness Drink - это новая эра в безалкогольных напитках. Future Wellness Drink направлен на снижение рисков связанных с черезмерным потреблением сахара, а также употреблением искуственных подсластителей. Разработанная линейка напитков, предназначена для людей, желающих вести здоровый образ жизни.",
    "В составе Future Wellness Drink:",
    "8 витаминов группы B, влияющие на все жизненно важные функции организма человека;",
    "Витамин E - мощный антиоксидант, укрепляющий иммунитет и защищающий клетки организма от преждевременного старения;",
    "Витамин С - крайне необходимый для нормального функционирования организма.",
    "Разрабатывая напиток Future Wellness Drink мы понимали, что в современном мире, отказавшись от сахара, очень сложно найти низкокаллорийные напитки без искусственных подсластителей. Именно поэтому в составе напитка используется экстракт стевии, который является природным сахарозаменителем, обладающий противомикробным действием, а также имеющий нулевой гликемический индекс.",
    "Мы уделили огромное внимание к выбору ингридиентов для напитков. Благодаря этому Future Wellness Drink содержит только натуральные ароматизаторы и подсластители. Мы гордимся тем, что запах и вкус напитка достигается за счет экстрактов ягод, фруктов, овощей и растений."
];
let ruImgTaste = [
    [imgTasteRu1, imgTasteRu2, imgTasteRu3, imgTasteRu4],
    [imgTasteRu5, imgTasteRu6, imgTasteRu7, imgTasteRu8],
    [imgTasteRu9, imgTasteRu10, imgTasteRu11, imgTasteRu12],
    [imgTasteRu13, imgTasteRu14, imgTasteRu15, imgTasteRu16],
    red360, yellow360, green360
];
let ruTextTasteKiwi = [
    "КИВИ", "МАНГОСТИН",
    "Насладитесь восхитительным сочетанием спелого сладкого киви, дополненного сливочным вкусом спелого мангостина. В составе напитка используются ароматизаторы исключительно растительного происхождения. В качестве подсластителей используется экстракт листьев стевии и неогесперидин (экстракт кожуры красного грейпфрута), которые обладают нулевым гликемическим индексом. Напиток обладает идеальным балансом вкуса и пользы, который служит отличным зарядом для здорового образа жизни.",
];
let ruTextTasteMango = [
    "МАНГО", "МАРАКУЙЯ", 
    "Насладитесь ярким тропическим сочетанием манго и маракуйи. В составе напитка используются ароматизаторы исключительно растительного происхождения. В качестве подсластителей используется экстракт листьев стевии и неогесперидин (экстракт кожуры красного грейпфрута), которые обладают нулевым гликемическим индексом. Напиток обладает идеальным балансом вкуса и пользы, который служит отличным зарядом для здорового образа жизни.", 
];
let ruTextTasteStrawberry = [
    "КЛУБНИКА", "МЯТА",
    "Насладитесь умопомрачительно тонким вкусом спелой клубники, дополненным свежестью мяты. В составе напитка используются ароматизаторы исключительно растительного происхождения. В качестве подсластителей используется экстракт листьев стевии и неогесперидин (экстракт кожуры красного грейпфрута), которые обладают нулевым гликемическим индексом. Напиток обладает идеальным балансом вкуса и пользы, который служит отличным зарядом для здорового образа жизни.",
];
let ruTextTasteBase = [
    "ПИЩЕВАЯ", "ЦЕННОСТЬ",
    "ВИТАМИНЫ",
    "на 330 мл",
];
let ruFooter = [
    "Свяжитесь с нами",
    "M46, Dawood Bldg,  Hor Al Anz, Dubai, UAE",
    "tel. +971 55 346 7455",
    "info@future-drink.ae",
    "Имя",
    "Введите ваше имя",
    "Email",
    "Введите электронный адрес",
    "Телефон",
    "Введите номер телефона",
    "Текст сообщения",
    "Отправить сообщение",
    "Нажимая на кнопку \"Отправить сообщение\", я даю согласие на ",
    "обработку своих персональных данных",
    "Условия и положения",
    "Политика конфидециальности",
    "© 2022 Future Wellnes Drink. All rights reserved",
];
let ruLang = makeLang(navRu, textRu, ruImgTaste, [ruTextTasteKiwi, ruTextTasteMango, ruTextTasteStrawberry, ruTextTasteBase], ruFooter);
export default ruLang;