import React, { useState, Suspense, useEffect, startTransition  } from 'react'
import * as THREE from "three";
import TWEEN from '@tweenjs/tween.js';
import { Canvas, useThree, useFrame } from '@react-three/fiber'
import { Preload, PerspectiveCamera, Stats, useProgress, SpotLight, PerformanceMonitor, Plane  } from '@react-three/drei'
import { useGesture } from '@use-gesture/react';
import round from 'lodash/round'

import Scene from './Scene/Scene';
import startAnime from './Animate/startAnime';
import PlateBackground from './Scene/Environment/PlateBackground';



let sunParametr = {
    azimuth: 180,
    elevation: 0,
}
let stoper = true;

function MainThree({country, getSwiper, swiperMoved}) {
    const [sun, getSun] = useState(new THREE.Vector3())
    function Loader() {
        const { active, progress, errors, item, loaded, total } = useProgress()
        let progressBar = document.getElementById('loadingForm')
        let loaderDiv = document.getElementById('loader');
        if (loaderDiv !== null) {
            progressBar.innerHTML = 'Loading ' + Math.floor(progress) + '%'
            if (progress === 100) {
                if (!loaderDiv.classList.contains("hidHtml")) {
                    loaderDiv.classList.toggle('hidHtml')
                }
            } else {
                if (loaderDiv.classList.contains("hidHtml")) {
                    loaderDiv.classList.toggle('hidHtml')
                }
            }
        }
        
    }
    const [dpr, setDpr] = useState(1)
    const [animeObject, getAnimeObject] = useState();

    const swiperMover = [startAnime, animeObject];
    if (stoper && swiperMover[1] !== undefined) {
        getSwiper(swiperMover);
        stoper = false;
    }
    const swiper = useGesture({
        onWheelStart: ({direction}) => {

                startAnime('swipe', direction[1], animeObject);
            
        },
    })
    
    
    return (
            <div id="canvas_container" className='PointerEvents'>
                <Canvas linear shadows
                        {...swiper()}
                        gl = {{
                                toneMapping: THREE.LinearToneMapping,
                                toneMappingExposure: 0.75,
                                shadowMap: {
                                    enabled: true,
                                    type: THREE.PCFSoftShadowMap,
                                }
                            }}
                        dpr={dpr}
                >
                    <Suspense fallback={<Loader />}>
                        <PerformanceMonitor id='perfomase'
                            onChange={({ factor }) => {
                                setDpr(round(0.73 + 1.27 * factor, 1))
                            }}/>
                        <PerspectiveCamera makeDefault position={[0, 8-30*0, 25]} fov={50} rotation={[-0.15, 0, 0]} far={250}/>
                        <Scene sun={sun} sunParametr={sunParametr} country={country} getAnimeObject={getAnimeObject} swiperMoved={swiperMoved}/>
                        <Stats />
                        <PlateBackground />
                        <Preload all />     
                    </Suspense>       
                </Canvas>
            </div>
    );
}

export default MainThree;