import makeLang from "./lang";
// IMG
    // IMG LANG
    import langRu from '../img/lang/ru.png';
    import langEn from '../img/lang/en.png';
    import langDe from '../img/lang/de.png';
    // 360
    import red360 from '../img/deg360/red360.png';
    import yellow360 from '../img/deg360/yellow360.png';
    import green360 from '../img/deg360/green360.png';
    // EN
    import imgTasteEn1 from '../img/taste/en/left/leftGreenEn1.png';
    import imgTasteEn2 from '../img/taste/en/left/leftGreenEn2.png';
    import imgTasteEn3 from '../img/taste/en/left/leftGreenEn3.png';
    import imgTasteEn4 from '../img/taste/en/left/leftGreenEn4.png';
    import imgTasteEn5 from '../img/taste/en/left/leftRedEn1.png';
    import imgTasteEn6 from '../img/taste/en/left/leftRedEn2.png';
    import imgTasteEn7 from '../img/taste/en/left/leftRedEn3.png';
    import imgTasteEn8 from '../img/taste/en/left/leftRedEn4.png';
    import imgTasteEn9 from '../img/taste/en/left/leftYellowEn1.png';
    import imgTasteEn10 from '../img/taste/en/left/leftYellowEn2.png';
    import imgTasteEn11 from '../img/taste/en/left/leftYellowEn3.png';
    import imgTasteEn12 from '../img/taste/en/left/leftYellowEn4.png';
    import imgTasteEn13 from '../img/taste/en/right/ValueEn1.png';
    import imgTasteEn14 from '../img/taste/en/right/ValueEn2.png';
    import imgTasteEn15 from '../img/taste/en/right/ValueEn3.png';
    import imgTasteEn16 from '../img/taste/en/right/ValueEn4.png';
////////////////////////////////////////////////////////////////////////
let navEng = ["HOME", "FLAVORS", "WHERE TO BUY", "CONTACT US", langEn, langRu, langDe];
let textEng = [
    "Future Wellness Drink is a new era in soft drinks. Future Wellness Drink aims to reduce the risks associated with excessive consumption of sugar, as well as the use of artificial sweeteners. The developed line of drinks is intended for people who want to lead a healthy lifestyle.",
    "As part of Future Wellness Drink:",
    "8 B-vitamins that affect all vital functions of the human body",
    "Vitamin E is a powerful antioxidant that strengthens the immune system and protects body cells from premature aging.",
    "Vitamin C is essential for the normal functioning of the body.",
    "When developing the Future Wellness Drink, we knew that in today's sugar-free world, it is very difficult to find low-calorie drinks without artificial sweeteners. That is why the composition of the drink uses stevia extract, which is a natural sweetener with antimicrobial action, and also has a zero glycemic index.",
    "We have paid great attention to the selection of ingredients for drinks. As a result, Future Wellness Drink contains only natural flavors and sweeteners. We are proud that the smell and taste of the drink is achieved through extracts of berries, fruits, vegetables and plants."
];
let enImgTaste = [
    [imgTasteEn1, imgTasteEn2, imgTasteEn3, imgTasteEn4],
    [imgTasteEn5, imgTasteEn6, imgTasteEn7, imgTasteEn8],
    [imgTasteEn9, imgTasteEn10, imgTasteEn11, imgTasteEn12],
    [imgTasteEn13, imgTasteEn14, imgTasteEn15, imgTasteEn16],
    red360, yellow360, green360
];
let enTextTasteKiwi = [
    "KIWI", "MANGOSTEEN",
    "Enjoy the delicious combination of ripe sweet kiwi fruit complemented by the creamy taste of ripe mangosteen. The composition of the drink uses flavorings exclusively of plant origin. As sweeteners, stevia leaf extract and neohesperidin (red grapefruit peel extract) are used, which have a zero glycemic index. The drink has the perfect balance of taste and benefits, which is an excellent charge for a healthy lifestyle."
];
let enTextTasteMango = [
    "MANGO", "PASSION",
    "Enjoy the vibrant tropical combination of mango and passion fruit. The composition of the drink uses flavorings exclusively of plant origin. As sweeteners, stevia leaf extract and neohesperidin (red grapefruit peel extract) are used, which have a zero glycemic index. The drink has the perfect balance of taste and benefits, which is an excellent charge for a healthy lifestyle."
];
let enTextTasteStrawberry = [
    "STRAWBERRY", "MINT",
    "Enjoy the breathtakingly subtle taste of ripe strawberries, complemented by the freshness of mint. The composition of the drink uses flavorings exclusively of plant origin. As sweeteners, stevia leaf extract and neohesperidin (red grapefruit peel extract) are used, which have a zero glycemic index. The drink has the perfect balance of taste and benefits, which is an excellent charge for a healthy lifestyle."
];
let enTextTasteBase = [
    "NUTRITIONAL", "VALUE",
    "VITAMINS",
    "per 330 ml"
];
let engFooter = [
    "CONTACT US", 
    "M46, Dawood Bldg,  Hor Al Anz, Dubai, UAE",
    "tel. +971 55 346 7455", 
    "info@future-drink.ae",
    "Name",
    "Please enter your name",
    "Email",
    "Please enter your email",
    "Phone",
    "Please enter your phone number",
    "Type your question here",
    "Send a message",
    "By clicking on the \"Send message\" button, I consent to ",
    "the processing of my personal data",
    "Terms and Conditions",
    "Privacy Policy",
    "© 2023 Future Wellnes Drink. All rights reserved"
];
let engLang = makeLang(navEng, textEng, enImgTaste, [enTextTasteKiwi, enTextTasteMango, enTextTasteStrawberry, enTextTasteBase], engFooter);
export default engLang;