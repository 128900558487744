import * as THREE from "three";
import { useThree } from '@react-three/fiber'
import { Sky } from '@react-three/drei'

function UpdateSun({sun, sunParametr}) {
    const scene = useThree((state) => state.scene)
    const gl = useThree((state) => state.gl)
    const sky = useThree((state) => state.scene.getObjectByName('sky'))
    
    let phi = THREE.MathUtils.degToRad( 90 - sunParametr.elevation );
    let theta = THREE.MathUtils.degToRad( sunParametr.azimuth );
    sun.setFromSphericalCoords( 1, phi, theta );
    if (sky !== undefined ) {
        const pmremGenerator = new THREE.PMREMGenerator(gl)
        scene.environment = pmremGenerator.fromScene( sky ).texture;
        sky.material.uniforms[ 'sunPosition' ].value.copy( sun );
    }
}
function SkySun({sun, sunParametr}) {
    return (
        <>
            <UpdateSun sun={sun} sunParametr={sunParametr} />
            <Sky scale={10000} 
                turbidity={10}
                rayleigh={2}
                mieCoefficient={0.005}
                mieDirectionalG={0.8}
                sunPosition={sun}
                name='sky'
            />
            
        </>
    );
}

export default SkySun;