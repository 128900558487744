import { Gltf } from '@react-three/drei'
import {strPos, strRot} from './strPos'
import {kiwiPos, kiwiRot} from './kiwiPos'

function PositionFrut({model, minHeight, maxHeight, scale}) {
    let widthDelta = -60 / 7;
    let heightDelta = (minHeight - maxHeight ) / 5
    let arr = []
    let index = 0  
    let name = model.slice(14, 19)

    let arrPos
    let arrRot
    if (name === "straw") {
        arrPos = strPos
        arrRot = strRot
    } else if ("kiwi.") {
        arrPos = kiwiPos
        arrRot = kiwiRot
    }
    
    for (let y = 0; y < 5; y++) {
        for (let x = 0; x < 7; x++) { 
                arr[index] = <Gltf key={name + x + y} src={model} receiveShadow castShadow 
                    name={name + index} scale={[scale, scale, scale]} 
                    position={arrPos[index]} rotation={arrRot[index]} 
                />
            index++
            
        }
        
    }
    return  <>
            { arr }
        </>
}

export default PositionFrut;

