import * as THREE from "three";

function AllLights() {
    return ( 
        <>
           <directionalLight 
                position={[-5, 10, 7.5]}
                intensity={5}
                castShadow={true}
                color = {new THREE.Color("hsl(0,0%,100%)")} 
                shadow-camera-near = {0.1}
                shadow-camera-far = {500}
                shadow-camera-right = {17}
                shadow-camera-left = {-17}
                shadow-camera-top	= {17}
                shadow-camera-bottom = {-17}
                shadow-mapSize-width = {512}
                shadow-mapSize-height = {512}
                shadow-radius = {4}
                shadow-bias = {-0.0005}
            />
            
            
            <spotLight color={0xffffff} intensity={0.7} position={[0, 20, 25]}  distance={100}/>
            <ambientLight color={0x404040}/>
            <directionalLight position={[10, 0, 10]} intensity={3.5} castShadow={false} color={0x404040} /> 
        </>
    );
}

export default AllLights;