import React, {useState} from "react";

// LANG
import ruLang from "./Lang/ru";
import engLang from "./Lang/en";
import deLang from "./Lang/de";
// COUNTRY
import {countryArr} from "./country/country"
// COMPONENT 
import MainHtml from "./component/Html/MainHtml";
import MainThree from "./component/Three JS/MainThree";
// CSS
import './css/font.css'
import './css/default.css'


function App() {
    // LANG
    let startLang = 'en'; //(navigator.language || navigator.userLanguage).substr(0, 2).toLowerCase();
    switch (startLang) {
        case 'ru': startLang = ruLang; break;
        case 'en': startLang = engLang; break;
        case 'de': startLang = deLang; break;
    }
    const [lang, setLang] = useState(startLang);
    const changeLang = (lang) => {
        console.log(11);
        switch (lang) {
            case ruLang.navigation[4]: setLang(ruLang); break;
            case engLang.navigation[4]: setLang(engLang); break;
            case deLang.navigation[4]: setLang(deLang); break;
        }
    }
    //console.log(lang);
    //////////////////////////////////////////////////////////////
    // COUNTRY
    const [country, setCountry] = useState(countryArr[0])
    let changeCountry = (country) => {
        console.log(countryArr[7].country[1]);
        for (let i = 0; i < countryArr.length; i++) {
            if (countryArr[i].country[1] === country) {
                console.log(countryArr[i].country[1] + '   ' + country);
                setCountry(countryArr[i])
                break;
            }
        }
    }
    //////////////////////////////////////////////////////////////
    const [swiper, getSwiper] = useState()
    return (
        <>
            <MainHtml lang={lang} changeLang={changeLang} country={country.country} setCountry={changeCountry} swiper={swiper}/>
            <MainThree lang={lang} country={country} getSwiper={getSwiper} swiperMoved={swiper}/>
        </>
    );
}

export default App;
