import { Canvas, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useThree, useFrame } from '@react-three/fiber'

import plate1 from '../../../../img/shop/imgSop1-0.png'
import plate2 from '../../../../img/shop/imgSop1-1.png'
import plate3 from '../../../../img/shop/imgSop1-2.png'
import plate4 from '../../../../img/shop/imgSop1-3.png'
import plate5 from '../../../../img/shop/imgSop1-4.png'
import plate6 from '../../../../img/shop/imgSop1-5.png'


import { useDrag, useWheel, useGesture } from "@use-gesture/react"
import TWEEN from '@tweenjs/tween.js';

function PlateT({pos, rot, img, controler, position}) {
    
    return (<>
    <mesh position={pos} rotation={rot} {...controler(position)} name={'shop' + position}>
        <planeGeometry attach="geometry" args={[10, 5]}/>
        <meshPhongMaterial map={img} toneMapped={false}/>
    </mesh>
    </>)
}
let arrTween = [[], [], [], [], [], []];
let arrPosition = [-1, 2, -1, -1, 2, -1];
let arrRotation = [0.52, 0, -0.52, 0.52, 0, -0.52];
let timeTween = 300;
function Plate({country}) {
    let chosenPlate = useThree().scene.getObjectByName('Plate')
    const plateControl = useGesture({
        onHover: ({args: [position], active}) => {
            let  divCanvas = document.getElementsByTagName('canvas')[0];
           
            if (active && !divCanvas.classList.contains('cursorPoinder')) {
                divCanvas.classList.toggle('cursorPoinder');
            } else if(divCanvas.classList.contains('cursorPoinder')) {
                
                divCanvas.classList.toggle('cursorPoinder');
            }
            
            if (chosenPlate !== undefined) {
                let zPosition = chosenPlate.children[position].position.z
                /* Тело */
                if (arrTween[position].length !== 0) {
                    for (let i = 0; i < arrTween[position].length; i++) {
                        arrTween[position][i].stop();
                    }
                    arrTween[position] = [];
                }
                function anime(positionT, rotationT) {
                    let positionTween = new TWEEN.Tween(chosenPlate.children[position].position);
                    positionTween.to({z: positionT}, timeTween).start();
                    arrTween[position].push(positionTween);
                    if (position === 0 || position === 2 || position === 3 || position === 5) {
                        let rotationTween = new TWEEN.Tween(chosenPlate.children[position].rotation);
                        rotationTween.to({y: rotationT,}, timeTween).start()
                        arrTween[position].push(rotationTween);
                    }
                }
                if (active) {
                    anime(3, 0);
                } else {
                    anime(arrPosition[position], arrRotation[position])
                }
            }
        },
    })
    return ( 
        <group name='Plate'>
            <PlateT pos={[10.5, -143, -1]} rot={[0, 0.52, 0]} img={country[0]} controler={plateControl} position={0}/>
            <PlateT pos={[0, -143, 2]} rot={[0, 0, 0]} img={country[1]} controler={plateControl} position={1}/> 
            <PlateT pos={[-10.5, -143, -1]} rot={[0, -0.52, 0]} img={country[2]} controler={plateControl} position={2}/> 
            <PlateT pos={[10.5, -149, -1]} rot={[0, 0.52, 0]} img={country[3]} controler={plateControl} position={3}/> 
            <PlateT pos={[0, -149, 2]} rot={[0, 0, 0]} img={country[4]} controler={plateControl} position={4}/> 
            <PlateT pos={[-10.5, -149, -1]} rot={[0, -0.52, 0]} img={country[5]} controler={plateControl} position={5}/> 
        </group>
    );
}

export default Plate;