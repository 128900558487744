// RED
let startOptionRed = {
    // START parametrs
    startPosition: {x:-7, y:-0.5, z:-5},
    startRotation: {x:0, y:-0.65, z:0},
    // SOLO parametrs
    soloPosition: {x:-7.8, y:-32.5, z:2.4}, 
    soloRotation: {x:0, y: -0.35, z:0.11},
        soloFluctPositionY: -32.5 - 1.5,
        soloFluctRotationZ: -0.11,
    // TRIO parametrs
    trioPosition: {x: -7.8, y: -122.5, z: 2.4,},
    trioRotation: {x: 0, y: -0.35, z: 0}, 
        trioFluctPosition: {y: -122},
        trioFluctTime: 4000, 
}
// YELLOW
let startOptionYellow = {
    // START parametrs
    startPosition: {x:0, y:-0.5, z:0},
    startRotation: {x:0, y:-0.65, z:0},
    // SOLO parametrs
    soloPosition: {x:0, y:-62.5, z:2.4}, 
    soloRotation: {x:0, y: -0.65, z:0.11},
        soloFluctPositionY: -62.5 - 1.5,
        soloFluctRotationZ: -0.11,
    // TRIO parametrs
    trioPosition: {x: 0, y: -122.5, z: 2.4,},
    trioRotation: {x: 0, y: -0.65, z: 0}, 
        trioFluctPosition: {y: -122},
        trioFluctTime: 5000, 
}
// GREEN
let startOptionGreen = {
    // START parametrs
    startPosition: {x:7, y:-0.5, z:-5},
    startRotation: {x:0, y:-0.65, z:0},
    // SOLO parametrs
    soloPosition: {x:7.8, y:-92.5, z:2.4}, 
    soloRotation: {x:0, y: -0.95, z:-0.11},
        soloFluctPositionY: -92.5 - 1.5,
        soloFluctRotationZ: 0.11,
    // TRIO parametrs
    trioPosition: {x: 7.8, y: -122.5, z: 2.4,},
    trioRotation: {x: 0, y: -0.95, z: 0}, 
        trioFluctPosition: {y: -122},
        trioFluctTime: 6000, 
}

export {startOptionRed, startOptionYellow, startOptionGreen};