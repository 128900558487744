import TWEEN from '@tweenjs/tween.js';

import pcAnimeControle from './pc/pcHtml'

let listOfAnime = [];
let animeControle;

let testIsPlaying = () => {
    for (let i = 0; i < listOfAnime.length; i++) {
        for (let d = 0; d < listOfAnime[i].length; d++) {
            if (listOfAnime[i][d].isPlaying()) {
                return true;
            }
        }
    }
    return false;
}
let cameraAnime = (animeObject, position, pustPosition) => {
    let cameraAnime = new TWEEN.Tween(animeObject.camera.position);
    let endPoint, time;
    if (!Number.isInteger(position)) {
        endPoint = 8 - 30 * Math.floor(position)
        time = 0;
    }else if (position !== 6) {
        endPoint = 8 - 30 * position
        time = 1000;
        if (pustPosition === 6) time = 0;
    }else {
        endPoint =  8 - 30 * pustPosition;
        time = 0;
    }
    cameraAnime.easing(TWEEN.Easing.Cubic.In);
    cameraAnime.to({y: endPoint}, time);
    cameraAnime.onStart(() =>  animeControle.invisAllContainer())
    cameraAnime.onComplete(() => {
        animeControle.animeCompilation(position, pustPosition, animeObject);
    });
    return cameraAnime;
}
let createSecondAnime = (position, redCan, yellowCan, greenCan, pustPosition) => {
    switch (position) {
        case 0:
            return [redCan.moveStartPosition(), yellowCan.moveStartPosition(), greenCan.moveStartPosition()];
        case 1:
            return [redCan.moveSoloCan()];
        case 2:
            return [yellowCan.moveSoloCan()];
        case 3:
            return [greenCan.moveSoloCan()];
        case 4:
            return [redCan.moveTrioCan(pustPosition, 1), yellowCan.moveTrioCan(pustPosition, 2), greenCan.moveTrioCan(pustPosition, 3)];
        case 5:
        case 6:
            return undefined;
        case 1.1:
            return [redCan.moveToTaste(position)];
        case 2.2:
            return [yellowCan.moveToTaste(position)];
        case 3.3:
            return [greenCan.moveToTaste(position)];
        case 4.1:
            return [redCan.moveToTaste(position), yellowCan.moveToInvis(), greenCan.moveToInvis()];
        case 4.2:
            return [redCan.moveToInvis(), yellowCan.moveToTaste(position), greenCan.moveToInvis()];
        case 4.3:
            return [redCan.moveToInvis(), yellowCan.moveToInvis(), greenCan.moveToTaste(position)];
    }
}
let createAnime = (animeObject, position, pustPosition) => {
    let camera = cameraAnime(animeObject, position, pustPosition);
    let second = createSecondAnime(position, animeObject.redCan, animeObject.yellowCan, animeObject.greenCan, pustPosition);
    let secondFin = [];
    if (second !== undefined) {
        for (let i = 0; i < second.length; i++) {
            if (second[i] !== undefined) secondFin.push(second[i]);
        }
    }
    if (secondFin.length !== 0) camera.chain(...secondFin);
    let arr = [camera];
    for (let i = 0; i < secondFin.length; i++) {
        if (secondFin[i] !== undefined) arr.push(arr[i]);
    }
    
    listOfAnime.push(arr);
}

function pcAnimeList(animeObject, pustPosition) {
    if (animeControle === undefined) animeControle = new pcAnimeControle();
    let position = animeObject.dinamicOption.position;
    animeControle.turnOnCircle(position)
    createAnime(animeObject, position, pustPosition);
    let activeStatus = testIsPlaying();
    if (activeStatus) {
        listOfAnime[listOfAnime.length - 2][listOfAnime[listOfAnime.length - 2].length -1].chain(listOfAnime[listOfAnime.length - 1][0])
    } else {
        listOfAnime = [listOfAnime[listOfAnime.length - 1]]
        listOfAnime[listOfAnime.length - 1][0].start();
    }

}

export default pcAnimeList;