import pcAnimeList from "./pcAnimeList";

let pustPosition;
let scrollDown;

function startAnime(type, step, animeObject) {
    //console.log(animeObject);
    pustPosition = animeObject.dinamicOption.position;
    if (type === 'swipe' && Number.isInteger(animeObject.dinamicOption.position)) {
        animeObject.dinamicOption.position += step;
        
        if (animeObject.dinamicOption.position < 0) {
            animeObject.dinamicOption.position = 0;
        } else if (animeObject.dinamicOption.position > 6) {
            animeObject.dinamicOption.position = 6
        } else {
            if (scrollDown === undefined) {
                scrollDown = document.getElementById('scrollDown');
                scrollDown.classList.toggle('invisibleDiv');
            }
            pcAnimeList(animeObject, pustPosition);
        }
    } else if (type === 'navigation' && Number.isInteger(animeObject.dinamicOption.position)) {
        if (animeObject.dinamicOption.position === step) return;
        animeObject.dinamicOption.position = step;
        pcAnimeList(animeObject, pustPosition);
    } else if ( type === 'taste' && (Number.isInteger(animeObject.dinamicOption.position) || step === null) ) {
        
        if (step === null) {
            animeObject.dinamicOption.position = Math.floor(animeObject.dinamicOption.position);
        } else {
            animeObject.dinamicOption.position += step;
        }
        pcAnimeList(animeObject, pustPosition);
        
    }
}

export default startAnime;