import { useGesture } from '@use-gesture/react';
import '../../../css/footerSection.css'
import logoWhite from '../../../img/logo/logoWight.png'
import instagram from '../../../img/logo/instagram.png'
import facebook from '../../../img/logo/FaceBook.png'
import youTube from '../../../img/logo/youTube.png'
import tikTok from '../../../img/logo/TikTok.png'

function Footer({lang, swiper}) {
    const swiperMoved = useGesture({
        onWheelStart: ({direction}) => {
            if (swiper !== undefined) { swiper[0]('swipe', direction[1], swiper[1]) };
        },
    })
    return (
        <>
            <div className='footerSection invisibleDiv' id='absFF' {...swiperMoved()} >
                <div className="" id="contact">
                    <div id="map" className="section">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d901.9483891116378!2d55.334104269654155!3d25.277529211297917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE2JzM5LjEiTiA1NcKwMjAnMDUuMSJF!5e0!3m2!1sru!2sru!4v1698689733463!5m2!1sru!2sru" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div id="formContact" className="section" >
                        <h5>{lang[0]}</h5>
                        <p>{lang[1]}</p>
                        <p>{lang[2]}</p>
                        <p>{lang[3]}</p>
                        <form action="" method="post">
                            <p>
                                <label htmlFor="name">{lang[4]}</label><br/>
                                <input type="text"  name="name" placeholder={lang[5]} required />
                            </p>
                            <p>
                                <label htmlFor="email">{lang[6]}</label><br/>
                                <input type="email" name="email" placeholder={lang[7]} required /><br/>
                            </p>
                            <p>
                                <label htmlFor="tel">{lang[8]}</label><br/>
                                <input type="tel" name="tel" placeholder={lang[9]} required /><br/>
                            </p>
                            <p>
                                <label htmlFor="message">{lang[10]}</label><br/>
                                <textarea name="message" co="40" rows="6" required ></textarea>
                            </p>
                            <input name="bezspama" type="text" />
                            <p>
                                <button type="submit" name="message_frm">{lang[11]}</button>
                            </p>
                            <p id="police">{lang[12]}<a href="./privacy.html">{lang[13]}</a>.</p>
                        </form>
                    </div>
                </div>

                <footer id="foot">
                    <div>
                        <p id="footPUp">{lang[14]}</p>
                        <p id="footPDown">{lang[15]}</p>
                        <p>{lang[16]}</p>
                    </div>
                    <div className="flexFooter">
                        <img src={logoWhite} alt="Future logo" id="footLogo"/>
                    </div>
                    <div className="flexFooter contactFooter">
                        <img src={instagram} alt="instagram"/>
                        <img src={facebook} alt="Facebook"/>
                        <img src={youTube} alt="YouTube"/>
                        <img src={tikTok} alt="TikTok" id='lastRight'/>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Footer;