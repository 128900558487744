import makeLang from "./lang";
// IMG
    // IMG LANG
    import langRu from '../img/lang/ru.png';
    import langEn from '../img/lang/en.png';
    import langDe from '../img/lang/de.png';
    // 360
    import red360 from '../img/deg360/red360.png';
    import yellow360 from '../img/deg360/yellow360.png';
    import green360 from '../img/deg360/green360.png';
    // DE
    import imgTasteDe1 from '../img/taste/de/left/leftGreenDe1.png';
    import imgTasteDe2 from '../img/taste/de/left/leftGreenDe2.png';
    import imgTasteDe3 from '../img/taste/de/left/leftGreenDe3.png';
    import imgTasteDe4 from '../img/taste/de/left/leftGreenDe4.png';
    import imgTasteDe5 from '../img/taste/de/left/leftRedDe1.png';
    import imgTasteDe6 from '../img/taste/de/left/leftRedDe2.png';
    import imgTasteDe7 from '../img/taste/de/left/leftRedDe3.png';
    import imgTasteDe8 from '../img/taste/de/left/leftRedDe4.png';
    import imgTasteDe9 from '../img/taste/de/left/leftYellowDe1.png';
    import imgTasteDe10 from '../img/taste/de/left/leftYellowDe2.png';
    import imgTasteDe11 from '../img/taste/de/left/leftYellowDe3.png';
    import imgTasteDe12 from '../img/taste/de/left/leftYellowDe4.png';
    import imgTasteDe13 from '../img/taste/de/right/ValueDe1.png';
    import imgTasteDe14 from '../img/taste/de/right/ValueDe2.png';
    import imgTasteDe15 from '../img/taste/de/right/ValueDe3.png';
    import imgTasteDe16 from '../img/taste/de/right/ValueDe4.png';
////////////////////////////////////////////////////////////////////////
let navDe = ["HOME", "AROMEN", "WO ZU KAUFEN", "KONTAKT", langDe, langEn, langRu];
let textDe = [
    "Future Wellness Drink ist eine neue Ära bei Erfrischungsgetränken. Future Wellness Drink zielt darauf ab, die Risiken zu reduzieren, die mit übermäßigem Zuckerkonsum sowie der Verwendung künstlicher Süßstoffe verbunden sind. Die entwickelte Getränkelinie ist für Menschen gedacht, die einen gesunden Lebensstil führen möchten.",
    "Als Bestandteil von Future Wellness Drink",
    "8 B-Vitamine, die alle lebenswichtigen Funktionen des menschlichen Körpers beeinflussen",
    "Vitamin E ist ein starkes Antioxidans, das das Immunsystem stärkt und die Körperzellen vor vorzeitiger Alterung schützt.",
    "Vitamin C ist für das normale Funktionieren des Körpers unerlässlich.",
    "Bei der Entwicklung des Future Wellness Drinks wussten wir, dass es in der heutigen zuckerfreien Welt sehr schwierig ist, kalorienarme Getränke ohne künstliche Süßstoffe zu finden. Aus diesem Grund verwendet die Zusammensetzung des Getränks Stevia-Extrakt, der ein natürlicher Süßstoff mit antimikrobieller Wirkung ist und auch einen glykämischen Index von Null hat.",
    "Großes Augenmerk haben wir auf die Auswahl der Zutaten für Getränke gelegt. Aus diesem Grund enthält Future Wellness Drink ausschließlich natürliche Aromen und Süßungsmittel. Wir sind stolz darauf, dass der Geruch und Geschmack des Getränks durch Extrakte aus Beeren, Früchten, Gemüse und Pflanzen erreicht wird."
];
let deImgTaste = [
    [imgTasteDe1, imgTasteDe2, imgTasteDe3, imgTasteDe4],
    [imgTasteDe5, imgTasteDe6, imgTasteDe7, imgTasteDe8],
    [imgTasteDe9, imgTasteDe10, imgTasteDe11, imgTasteDe12],
    [imgTasteDe13, imgTasteDe14, imgTasteDe15, imgTasteDe16],
    red360, yellow360, green360
];
let deTextTasteKiwi = [
    "KIWI", "MANGOSTEEN",
    "Genießen Sie die köstliche Kombination aus reifen, süßen Kiwis, ergänzt durch den cremigen Geschmack reifer Mangostane. Die Zusammensetzung des Getränks verwendet Aromen ausschließlich pflanzlichen Ursprungs. Als Süßstoffe werden Stevia-Blattextrakt und Neohesperidin (Extrakt aus roten Grapefruitschalen) verwendet, die einen glykämischen Index von Null haben. Das Getränk hat die perfekte Balance zwischen Geschmack und Nutzen, was eine hervorragende Grundlage für einen gesunden Lebensstil darstellt."
];
let deTextTasteMango = [
    "MANGO", "PASSIONSFRUCHT",
    "Genießen Sie die lebendige tropische Kombination aus Mango und Maracuja. Die Zusammensetzung des Getränks verwendet Aromen ausschließlich pflanzlichen Ursprungs. Als Süßstoffe werden Stevia-Blattextrakt und Neohesperidin (Extrakt aus roten Grapefruitschalen) verwendet, die einen glykämischen Index von Null haben. Das Getränk hat die perfekte Balance zwischen Geschmack und Nutzen, was eine hervorragende Grundlage für einen gesunden Lebensstil darstellt."
];
let deTextTasteStrawberry = [
    "ERDBEERE", "MINZE",
    "Genießen Sie den atemberaubend subtilen Geschmack reifer Erdbeeren, ergänzt durch die Frische der Minze. Die Zusammensetzung des Getränks verwendet Aromen ausschließlich pflanzlichen Ursprungs. Als Süßstoffe werden Stevia-Blattextrakt und Neohesperidin (Extrakt aus roten Grapefruitschalen) verwendet, die einen glykämischen Index von Null haben. Das Getränk hat die perfekte Balance zwischen Geschmack und Nutzen, was eine hervorragende Grundlage für einen gesunden Lebensstil darstellt."
];
let deTextTasteBase = [
    "NÄHRWERT", "",
    "VITAMINE",
    "pro 330 ml"
];
let deFooter = [
    "KONTAKTIERE UNS",
    "M46, Dawood Bldg,  Hor Al Anz, Dubai, UAE",
    "tel. +971 55 346 7455",
    "info@future-drink.ae",
    "Name",
    "Bitte geben Sie Ihren Namen ein",
    "E-mail",
    "Bitte geben Sie Ihre E-Mail ein",
    "Telefonnummer",
    "Bitte geben sie ihre Telefonnummer ein",
    "Geben Sie ihre Frage hier ein",
    "SENDEN",
    "Durch Anklicken des Buttons „Nachricht senden“ stimme ich der ",
    "Verarbeitung meiner personenbezogenen Daten zu",
    "Geschäftsbedingungen",
    "Datenschutz-Bestimmungen",
    "© 2022 Future Wellnes Drink. All rights reserved",
];
let deLang = makeLang(navDe, textDe, deImgTaste, [deTextTasteKiwi, deTextTasteMango, deTextTasteStrawberry, deTextTasteBase], deFooter);
export default deLang;