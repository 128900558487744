import SkySun from "./Sky/SkySun";
import Ocean from "./Water/Ocean";
import WallWater from "./Water/WallWater";

function Environment({sun, sunParametr}) {
    return ( 
        <>
            <SkySun sun={sun} sunParametr={sunParametr}/>
            <Ocean sun={sun}/>
            <WallWater />
            
        </>
    );
}

export default Environment;