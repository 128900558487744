class pcAnimeControle {
    constructor() {
        this.canvasContainer = document.getElementById('canvas_container');
        this.taste = document.getElementsByClassName('taste');
        this.container = document.getElementsByClassName('container');
        this.footerSection = document.getElementById('absFF');
        this.sircleBar = document.getElementsByClassName('sircleBar');
    }
    

    switcherFooter(position) {
        if (position === 6 && !this.canvasContainer.classList.contains('invisibleDiv')) {
            this.canvasContainer.classList.toggle('invisibleDiv');
            this.footerSection.classList.toggle('invisibleDiv');
            this.containerActive(this.container);
            return;
        }
        if (this.canvasContainer.classList.contains('invisibleDiv')) {
            this.canvasContainer.classList.toggle('invisibleDiv');
            this.footerSection.classList.toggle('invisibleDiv');
            return;
        }
    }

    invisAllContainer() {
        for (let i = 0; i < this.taste.length; i++) {
            if (!this.taste[i].classList.contains('invisibleDiv')) {
                this.taste[i].classList.toggle('invisibleDiv');
            } 
        }
        for (let i = 0; i < this.container.length; i++) {
            if (!this.container[i].classList.contains('invisibleDiv')) {
                this.container[i].classList.toggle('invisibleDiv');
            }
        }
    }

    containerActive(classObj, activArr) {
        if (activArr !== undefined && !this.canvasContainer.classList.contains('invisibleDiv')) {
            activArr.forEach(element => {
                classObj[element].classList.toggle('invisibleDiv');
            });
        }
    }
    turnOffCircle() {
        for (let i = 0; i < this.sircleBar.length; i++) {
            if (this.sircleBar[i].classList.contains('activeSircle')) {
                this.sircleBar[i].classList.toggle('activeSircle');
            }
            
        }
    }  
    turnOnCircle(position) {
        this.turnOffCircle();
        this.sircleBar[Math.floor(position)].classList.toggle('activeSircle')
    }
    animeCompilation = (position, pustPosition, animeObject) => {
        
        animeObject.redCan.pauseAnimeFrutis();
        animeObject.yellowCan.pauseAnimeFrutis();
        animeObject.greenCan.pauseAnimeFrutis();

        animeObject.redCan.togleBackground(0, 0);
        animeObject.yellowCan.togleBackground(0, 0);
        animeObject.greenCan.togleBackground(0, 0);
        animeObject.redCan.togleBackground(1, 0);
        animeObject.yellowCan.togleBackground(1, 0);
        animeObject.greenCan.togleBackground(1, 0);
        
        //this.turnOnCircle(position);
        if (position === 0) {
            
        }else if (position === 1) {
            animeObject.redCan.resumeAnimeFrutis();
            
            this.containerActive(this.container, [0])
        }else if (position === 2) {
            animeObject.yellowCan.resumeAnimeFrutis();
            this.containerActive(this.container, [1, 2])
        }else if (position === 3) {
            animeObject.greenCan.resumeAnimeFrutis();
            this.containerActive(this.container, [3])
        }else if (position === 4) {
            this.containerActive(this.container, [4])
        }else if (position === 5) {
            if (pustPosition === 6) {
                this.switcherFooter(position)
            }
            this.containerActive(this.container, [5])
        }else if (position === 6) {
            this.switcherFooter(position);
        }else if (position === 1.1) {
            this.containerActive(this.taste, [0]);
            animeObject.redCan.togleBackground(0, 1);
        }else if (position === 2.2) {
            this.containerActive(this.taste, [1]);
            animeObject.yellowCan.togleBackground(0, 1);
        }else if (position === 3.3) {
            this.containerActive(this.taste, [2]);
            animeObject.greenCan.togleBackground(0, 1);
        }else if (position === 4.1) {
            this.containerActive(this.taste, [0]);
            animeObject.redCan.togleBackground(1, 1);
        }else if (position === 4.2) {
            this.containerActive(this.taste, [1]);
            animeObject.yellowCan.togleBackground(1, 1);
        }else if (position === 4.3) {
            this.containerActive(this.taste, [2]);
            animeObject.greenCan.togleBackground(1, 1);
        }
        
    }
}

export default pcAnimeControle;
