import { useState } from "react";
import TWEEN from '@tweenjs/tween.js';
import { useThree } from "@react-three/fiber";

import Can from "./Can/Can";
import Fruits from "./Fruits/Fruits";
import Plate from './PlateShope'

import {startOptionRed, startOptionYellow, startOptionGreen} from '../../Animate/optionCan'
import {dinamicOption} from '../../Animate/dinamicOption'
import ModelCan from "../../Animate/Can";

let stoper = false;
let dinamicOpt = dinamicOption;
function ActiveObj({stateAnimate, animeParametrs, country, getAnimeObject, swiperMoved}) {
    const [can, getCan] = useState();
    const [fruits, getFruits] = useState();
    const { camera } = useThree();
    //const gl = useThree();
    let redCan, yellowCan, greenCan;
    if (can !== undefined && !stoper) {
        stoper = true;
        redCan = new ModelCan(0, can[0], startOptionRed, fruits, can[3]);
        yellowCan = new ModelCan(1, can[1], startOptionYellow, fruits, can[4]);
        greenCan = new ModelCan(2, can[2], startOptionGreen, fruits, can[5]);
        let animeObject = {
            redCan: redCan,
            yellowCan: yellowCan,
            greenCan: greenCan,
            dinamicOption: dinamicOpt,
            camera: camera,
        };
        //console.log(gl);
        //redCan.moveTrioCan();
        getAnimeObject(animeObject);
    }
    return (<>
        <Can getCan={getCan} swiperMoved={swiperMoved} dinamicOption={dinamicOpt}/>
        <Fruits getFruits={getFruits}/>
        <Plate country={country}/>
    </>);
}

export default ActiveObj;