import { Canvas, useThree, useFrame } from '@react-three/fiber'
import * as THREE from "three";
import TWEEN from '@tweenjs/tween.js';

import Environment from "./Environment/Environment";
import AllLights from "../AllLights";
import ActiveObj from './ActiveObj/ActiveObj';

let phi = THREE.MathUtils.degToRad( 90 );
let theta = THREE.MathUtils.degToRad( 180 );

function Scene({sun, sunParametr, country, getAnimeObject, swiperMoved}) {
    let {scene} = useThree()
    //console.log(scene);
    
    
    const sky = scene.getObjectByName('sky');
    useFrame(() => {
        let thetaNew = THREE.MathUtils.degToRad( sunParametr.azimuth );
        if (theta != thetaNew) {
            theta = THREE.MathUtils.degToRad( sunParametr.azimuth );
            sun.setFromSphericalCoords( 1, phi, theta );
            sky.material.uniforms[ 'sunPosition' ].value.copy( sun );
        }
        TWEEN.update();
    })
    return (
        <>
            <AllLights />
            <Environment sun={sun} sunParametr={sunParametr}/> 
            <ActiveObj country={country.plate} getAnimeObject={getAnimeObject} swiperMoved={swiperMoved}/>
        </>
    );
}

export default Scene;