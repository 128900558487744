import React, { useRef, useMemo } from 'react'
import * as THREE from "three";
import { useThree, extend, useLoader, useFrame } from '@react-three/fiber'
import waterTex from './waterTex.jpeg'
import { Water } from 'three-stdlib'

extend({ Water })
function Ocean(props) {
    const ref = useRef()
    const gl = useThree((state) => state.gl)
    const waterNormals = useLoader(THREE.TextureLoader, waterTex)
    waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
    const geom = useMemo(() => new THREE.PlaneGeometry(10000, 10000), [])
    const config = useMemo(
      () => ({
        textureWidth: 512,
        textureHeight: 512,
        waterNormals,
        side: THREE.DoubleSide,
        //sunDirection: props.sun.normalize(),
        sunColor: 0xffffff,
        waterColor: 0x00ffff,
        distortionScale: 3.7,
        fog: false,
        format: gl.encoding
      }),
      [waterNormals]
    )
    useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta/3))
    return <water ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} />
}

export default Ocean;