import { useEffect } from "react";
import Kiwi from "./Kiwi";
import Mango from "./Mango";
import Strawberries from "./Strawberries";
import { useThree } from "@react-three/fiber";
import TWEEN from '@tweenjs/tween.js';
let stoper = false;
function createFrut(frut) {
    let time;
    let doubleStepStop = 0;
    let frutiAnimate = []
    for (let i = 0; i < frut.length; i++) {
        
        if (doubleStepStop === 0) {
            doubleStepStop++;
            time = 2500;
        } else if (doubleStepStop === 1) {
            doubleStepStop++;
            time = 2000;
        }else if (doubleStepStop === 2) {
            doubleStepStop++;
            time = 2300;
        } else if (doubleStepStop === 3) {
            time = 2500;
            doubleStepStop = 0;
        }
        if (frut[i].position !== undefined) {
            frutiAnimate[i] = new TWEEN.Tween(frut[i].position);
        
            frutiAnimate[i].to({y: (frut[i].position.y - .5)},  time)
            frutiAnimate[i].easing(TWEEN.Easing.Sinusoidal.InOut)
            frutiAnimate[i].repeat(Infinity);
            frutiAnimate[i].yoyo(true);
            frutiAnimate[i].start();
            frutiAnimate[i].pause();
        }
    }
    return frutiAnimate;
}
function Fruits({getFruits}) {
    const {scene} = useThree();
    let strawberries = scene.getObjectByName('strawberries');
    let mango = scene.getObjectByName('mango');
    let kiwi = scene.getObjectByName('kiwi');
    if (strawberries !==undefined && !stoper) {
        stoper = true;
        getFruits([createFrut(strawberries.children), createFrut(mango.children), createFrut(kiwi.children) ])
    }
    return (<>
        <group name="strawberries">
            <Strawberries />
        </group>
        <group name="mango">
            <Mango />
        </group>
        <group name="kiwi">
            <Kiwi />
        </group>
    </>);
}

export default Fruits;