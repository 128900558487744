import '../../../css/scrollBar.css'
import scrollDown from '../../../img/logo/scrollDown.png'
function ScrollBar() {
    return (
        <>
            <div id="scrollBar">
                <div className="sircleBar activeSircle"></div>
                <div className="sircleBar"></div>
                <div className="sircleBar"></div>
                <div className="sircleBar"></div>
                <div className="sircleBar"></div>
                <div className="sircleBar"></div>
                <div className="sircleBar"></div>
            </div>
            <div id="scrollDown">
                <img src={scrollDown} alt="" srcset="" />
            </div>
        </>
    );
}

export default ScrollBar;