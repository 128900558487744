import TWEEN from '@tweenjs/tween.js'; 

class ModelCan {
    constructor(num ,id, optionCan, fruitsAnime, background) {
        //Property
        this.num = num;
        this.id = id;
        this.optionCan = optionCan;
        this.fruitsAnime = fruitsAnime;
        this.background = background;
        //Can main move
        this.soloAnime = undefined;
        this.startAnime = undefined;
        this.trioAnime = undefined;
        this.tasteAnime = undefined;
        //Fluctuation
        this.fluctuationSolo = undefined;
        this.fluctuationTrio = undefined;
    }
    
    //Method
    //Fruties
    resumeAnimeFrutis = () => {
        for (let d = 0; d < this.fruitsAnime[this.num].length; d++) {
            this.fruitsAnime[this.num][d].resume();
        }
    }
    pauseAnimeFrutis = () => {
        for (let d = 0; d < this.fruitsAnime[this.num].length; d++) {
            this.fruitsAnime[this.num][d].pause();
        }
    }
    //Background
    togleBackground = (numBack, opacity) => {
        if (this.background[numBack].children[0].material.opacity === opacity) return;
        for (let i = 0; i < this.background[numBack].children.length; i++) {
            let opacityAnime = new TWEEN.Tween(this.background[numBack].children[i].material);
            opacityAnime.easing(TWEEN.Easing.Cubic.In);
            opacityAnime.to({opacity: opacity}, 1000);
            opacityAnime.start();
        }
    } 
    //Anime controle
    stopAnimeArr = (flucArr) => {
        if (flucArr !== undefined) {
            flucArr.forEach((elem) => {
                console.log(flucArr.length);
                elem.stop();
            });
            flucArr = undefined;
        }
        return undefined;
    }
    stopActiveAnimeArr = (flucArr) => {
        if (flucArr !== undefined) {
            flucArr.forEach((elem) => {
                console.log(flucArr.length);
                elem.stop();
            });
            flucArr = undefined;
        }
        return undefined;
    }
    //Fluctuation anime
    soloFluctuationCreate = () => {
        let tweenShakePos = new TWEEN.Tween(this.id.position);
        tweenShakePos.to({y: this.optionCan.soloFluctPositionY}, 2000)
        tweenShakePos.easing(TWEEN.Easing.Sinusoidal.InOut)
        tweenShakePos.repeat(Infinity);
        tweenShakePos.yoyo(true);
        let tweenShakeRot = new TWEEN.Tween(this.id.rotation);
        tweenShakeRot.to({z: this.optionCan.soloFluctRotationZ}, 3000)
        tweenShakeRot.easing(TWEEN.Easing.Quadratic.InOut)
        tweenShakeRot.repeat(Infinity);
        tweenShakeRot.yoyo(true);
        this.fluctuationSolo = [tweenShakePos, tweenShakeRot]
    }
    trioFluctuationCreate = () => {
        let tweenShakePos = new TWEEN.Tween(this.id.position);
        tweenShakePos.to({y: this.optionCan.trioFluctPosition.y}, this.optionCan.trioFluctTime);
        tweenShakePos.easing(TWEEN.Easing.Sinusoidal.InOut);
        tweenShakePos.repeat(Infinity);
        tweenShakePos.yoyo(true);
        this.fluctuationTrio = [tweenShakePos];
    }
    //Solo main move
    moveSoloCan = () => {
        if (this.fluctuationSolo !== undefined) return undefined
        
        let TweenX = new TWEEN.Tween(this.id.position);
        TweenX.to({ x: this.optionCan.soloPosition.x, }, 3000);
        TweenX.easing(TWEEN.Easing.Sinusoidal.Out);
        TweenX.onStart(() => {
            this.fluctuationTrio = this.stopAnimeArr(this.fluctuationTrio);
            
            this.startAnime = this.stopAnimeArr(this.startAnime);
            this.trioAnime = this.stopAnimeArr(this.trioAnime);
            this.tasteAnime = this.stopAnimeArr(this.tasteAnime);

            this.soloFluctuationCreate();

            this.soloAnime[1].start();
            this.soloAnime[2].start();
            this.soloAnime[3].start();
        });
        TweenX.onComplete(() => {
            this.fluctuationSolo[0].start();
            this.fluctuationSolo[1].start();
        });
        
        let TweenY = new TWEEN.Tween(this.id.position);
        TweenY.to({ y: this.optionCan.soloPosition.y, }, 3000);
        TweenY.easing(TWEEN.Easing.Back.Out);

        let TweenZ = new TWEEN.Tween(this.id.position);
        TweenZ.to({ z: this.optionCan.soloPosition.z, }, 3000);
        TweenZ.easing(TWEEN.Easing.Quartic.Out);
        
        let TweenRot = new TWEEN.Tween(this.id.rotation);
        TweenRot.to(this.optionCan.soloRotation, 3000);
        TweenRot.easing(TWEEN.Easing.Back.Out);

        this.soloAnime = [TweenX, TweenY, TweenZ, TweenRot];

        return TweenX;
    };
    //Star main move
    moveStartPosition = () => {
        if (this.fluctuationSolo === undefined && this.fluctuationTrio === undefined) return undefined;

        let TweenX = new TWEEN.Tween(this.id.position);
        TweenX.to({x: this.optionCan.startPosition.x}, 3000);
        TweenX.easing(TWEEN.Easing.Sinusoidal.Out);
        TweenX.onStart(() => {
            this.fluctuationSolo = this.stopActiveAnimeArr(this.fluctuationSolo);
            this.fluctuationTrio = this.stopActiveAnimeArr(this.fluctuationTrio);

            this.soloAnime = this.stopActiveAnimeArr(this.soloAnime);
            this.tasteAnime = this.stopActiveAnimeArr(this.tasteAnime);
            this.trioAnime = this.stopActiveAnimeArr(this.trioAnime);

            this.startAnime[1].start();
            this.startAnime[2].start();
            this.startAnime[3].start();
        });

        let TweenY = new TWEEN.Tween(this.id.position);
        TweenY.to({y: this.optionCan.startPosition.y}, 3000);
        TweenY.easing(TWEEN.Easing.Cubic.Out);

        let TweenZ = new TWEEN.Tween(this.id.position);
        TweenZ.to({z: this.optionCan.startPosition.z}, 3000);
        TweenZ.easing(TWEEN.Easing.Quartic.Out);

        let TweenRot = new TWEEN.Tween(this.id.rotation);
        TweenRot.to(this.optionCan.startRotation, 3000);
        TweenRot.easing(TWEEN.Easing.Back.Out);

        this.startAnime = [TweenX, TweenY, TweenZ, TweenRot]
        console.log('TWEEN');
        return TweenX;
    };
    //Trio main move
    moveTrioCan = (pustPosition, num) => {
        if (this.fluctuationTrio !== undefined) return undefined;
        let time;
        if ((10 * (pustPosition - 4.0).toFixed(1) === num) && !Number.isInteger(pustPosition)) {
            time = 1000;
            console.log(time);
        } else {
            time = 3000;
        }
        let TweenX = new TWEEN.Tween(this.id.position);
        TweenX.to({x: this.optionCan.trioPosition.x}, time);
        TweenX.easing(TWEEN.Easing.Sinusoidal.Out);
        TweenX.onStart(() => {
            this.fluctuationSolo = this.stopAnimeArr(this.fluctuationSolo);

            this.soloAnime = this.stopAnimeArr(this.soloAnime);
            this.startAnime = this.stopAnimeArr(this.startAnime);
            this.tasteAnime = this.stopAnimeArr(this.tasteAnime);

            this.trioFluctuationCreate();

            this.trioAnime[1].start();
            this.trioAnime[2].start();
            this.trioAnime[3].start();
        });
        TweenX.onComplete(() => {
            this.fluctuationTrio[0].start();
        });

        let TweenY = new TWEEN.Tween(this.id.position);
        TweenY.to({y: this.optionCan.trioPosition.y}, 3000);
        TweenY.easing(TWEEN.Easing.Back.Out);
        TweenY.onStart(() => { this.fluctuationSolo = this.stopActiveAnimeArr(this.fluctuationSolo) });
        
        let TweenZ = new TWEEN.Tween(this.id.position);
        TweenZ.to({z: this.optionCan.trioPosition.z}, 3000);
        TweenZ.easing(TWEEN.Easing.Back.Out);
        

        let TweenRot = new TWEEN.Tween(this.id.rotation);
        TweenRot.to(this.optionCan.trioRotation, 3000);

        this.trioAnime = [TweenX, TweenY, TweenZ, TweenRot];
        
        return TweenX;
    };
    //Taste move main
    moveToTaste = (position) => {
        let y;
        if (position >= 4) {
            y = this.optionCan.trioPosition.y;
        } else {
            y = this.optionCan.soloPosition.y
        }

        let TweenX = new TWEEN.Tween(this.id.position);
        TweenX.to({ x: 0, }, 2000);
        TweenX.easing(TWEEN.Easing.Sinusoidal.Out);
        TweenX.onStart(() => {
            console.log(this.fluctuationSolo);
            this.fluctuationSolo = this.stopActiveAnimeArr(this.fluctuationSolo);
            this.fluctuationTrio = this.stopActiveAnimeArr(this.fluctuationTrio);

            this.soloAnime = this.stopActiveAnimeArr(this.soloAnime);
            this.startAnime = this.stopActiveAnimeArr(this.startAnime);
            this.trioAnime = this.stopActiveAnimeArr(this.trioAnime);

            TweenY.start();
            TweenZ.start();
            TweenRot.start();
        });
        
        let TweenY = new TWEEN.Tween(this.id.position);
        TweenY.to({ y: y, }, 2000);
        TweenY.easing(TWEEN.Easing.Back.Out);

        let TweenZ = new TWEEN.Tween(this.id.position);
        TweenZ.to({ z: this.optionCan.soloPosition.z, }, 2000);
        TweenZ.easing(TWEEN.Easing.Quartic.Out);
        
        let TweenRot = new TWEEN.Tween(this.id.rotation);
        TweenRot.to({x:0, y:-0.65, z:0}, 2000);
        TweenRot.easing(TWEEN.Easing.Back.Out);

        this.tasteAnime = [TweenX, TweenY, TweenZ, TweenRot]
        return TweenX;
    }
    moveToInvis = () => {
        let TweenY = new TWEEN.Tween(this.id.position);
        TweenY.to({ y: -90, }, 3000);
        TweenY.easing(TWEEN.Easing.Back.Out);
        TweenY.onStart(() => {
            this.fluctuationSolo = this.stopActiveAnimeArr(this.fluctuationSolo);
            this.fluctuationTrio = this.stopActiveAnimeArr(this.fluctuationTrio);

            this.soloAnime = this.stopActiveAnimeArr(this.soloAnime);
            this.startAnime = this.stopActiveAnimeArr(this.startAnime);
            this.trioAnime = this.stopActiveAnimeArr(this.trioAnime);
        });

        this.tasteAnime = [TweenY]
        return TweenY;
    }
}
export default ModelCan;