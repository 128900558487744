import '../../../css/TextCan.css'

import au from '../../../img/country/Austria.png'
import gr from '../../../img/country/Germany.png'
import sw from '../../../img/country/Switzerland.png'
import arrow from '../../../img/country/arrowShop.png'

function HtmlText({lang, country, setCountry}) {
    function closerAbsss () {
        let divHid = document.getElementsByClassName('countryDiv');
        for (let i = 1; i < divHid.length - 1; i++) {
            divHid[i].classList.toggle('heightZero');
        }
        divHid[divHid.length - 1].classList.toggle('rot')
    }
    return (
        <>
            <div className="container invisibleDiv" id="TextDescriptionFirst">
                <div>
                    <h1>Try the Future</h1>
                    <p>{lang[0]}</p>
                </div>
            </div>

            <div className="container invisibleDiv" id="TextDescriptionSecond1">
                <div className="one">
                    <h1>Future Vitamins</h1>
                    <p>{lang[1]}</p>
                    <p>{lang[2]}</p>
                    <p>{lang[3]}</p>
                    <p>{lang[4]}</p>
                </div>
            </div>
            <div className="container invisibleDiv" id="TextDescriptionSecond2">
                <div className="two">
                    <h1> Taste the Future</h1>
                    <p>{lang[5]}</p>
                </div>
            </div>

            <div className="container invisibleDiv" id="TextDescriptionThird">
                <div>
                    <h1> Feel the Future</h1>
                    <p>{lang[6]}</p>
                </div>
            </div>

            <div className="container invisibleDiv" id="TextDescriptionFourth">
                <div>
                    <h1> Choose Your Future </h1>
                </div>
            </div>
            <div id='TextDescriptionFifth' className='container invisibleDiv'>
                <h1> Where to buy </h1>
                <h2> choose your country</h2>
                <div className='country autoPoint'>
                    <div className='countryDiv'>
                        <img src={country[0]} alt="" />
                        <div>{country[1]}</div>
                    </div>
                    <div className='countryDiv heightZero' onClick={() => {setCountry(country[3]); closerAbsss()}}>
                        <img src={country[2]} alt="" />
                        <div>{country[3]}</div>
                    </div>
                    <div className='countryDiv heightZero' onClick={() => {setCountry(country[5]); closerAbsss()}}>
                        <img src={country[4]} alt="" />
                        <div>{country[5]}</div>
                    </div>
                    <div className='countryDiv heightZero' onClick={() => {setCountry(country[7]); closerAbsss()}}>
                        <img src={country[6]} alt="" />
                        <div>{country[7]}</div>
                    </div>
                    <div className='countryDiv heightZero' onClick={() => {setCountry(country[9]); closerAbsss()}}>
                        <img src={country[8]} alt="" />
                        <div>{country[9]}</div>
                    </div>
                    <div className='countryDiv heightZero' onClick={() => {setCountry(country[11]); closerAbsss()}}>
                        <img src={country[10]} alt="" />
                        <div>{country[11]}</div>
                    </div>
                    <div className='countryDiv heightZero' onClick={() => {setCountry(country[13]); closerAbsss()}}>
                        <img src={country[12]} alt="" />
                        <div>{country[13]}</div>
                    </div>
                    <div className='countryDiv heightZero' onClick={() => {setCountry(country[15]); closerAbsss()}}>
                        <img src={country[14]} alt="" />
                        <div>{country[15]}</div>
                    </div>
                    <div className='countryDiv heightZero' onClick={() => {setCountry(country[17]); closerAbsss()}}>
                        <img src={country[16]} alt="" />
                        <div>{country[17]}</div>
                    </div>
                    <img className='countryDiv' src={arrow} alt="" onClick={() => {
                        closerAbsss()
                    }}/>
                </div>
            </div>
        </>
    );
}

export default HtmlText;