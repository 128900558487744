let kiwiPos = [
    [
        21.939192010535507,
        -100.45817992371289,
        -16.64824809116123
    ],
    [
        14.488664315156457,
        -95.20843650935447,
        -19.36880788813069
    ],
    [
        10.896963673906056,
        -94.55934330843046,
        -16.744472414908252
    ],
    [
        -0.3238950997706169,
        -96.24494758047668,
        -16.72671063499714
    ],
    [
        -6.035392974186742,
        -94.54093532281478,
        -20.708521354613506
    ],
    [
        -16.881088539365017,
        -93.89187279735363,
        -14.94718202314654
    ],
    [
        -22.38630346974832,
        -93.80104631305574,
        -23.67683068810036
    ],
    [
        24.557379598631858,
        -96.16642745294288,
        -23.454723315069465
    ],
    [
        15.867976248814694,
        -91.46380935700385,
        -20.586742623050686
    ],
    [
        8.633553426622777,
        -93.24110022110156,
        -21.649770804561523
    ],
    [
        -3.515515643127462,
        -89.97650174439703,
        -20.94830667994431
    ],
    [
        -11.122476388832425,
        -92.1226304185771,
        -22.02905629887247
    ],
    [
        -13.932320288179307,
        -92.93015819887952,
        -19.312089714776622
    ],
    [
        -22.279892835545926,
        -90.649322896701,
        -21.235065808742313
    ],
    [
        27.244987498087635,
        -89.47882223315914,
        -15.753140765960373
    ],
    [
        17.768914599880652,
        -88.88730106912942,
        -15.224681748460304
    ],
    [
        8.32715516516156,
        -86.4687841762914,
        -20.532305535175688
    ],
    [
        3.1349599457364627,
        -88.80262415524349,
        -24.853141491152144
    ],
    [
        -8.288964387389921,
        -87.34973848408008,
        -19.396443373765116
    ],
    [
        -16.497823771100403,
        -88.96134340527082,
        -24.686157768802506
    ],
    [
        -27.004880579598016,
        -93.4708682116209,
        -23.370364586583058
    ],
    [
        22.434067820753064,
        -85.6331873949552,
        -21.56246643007971
    ],
    [
        17.763886038596187,
        -84.23562428136798,
        -22.676421287945995
    ],
    [
        5.932217551221156,
        -82.72724224135165,
        -15.711199685685317
    ],
    [
        -1.8444192480652353,
        -84.9065816773578,
        -14.104098945695561
    ],
    [
        -5.648813839350108,
        -83.07643876865272,
        -20.125767131543824
    ],
    [
        -20.011765398004638,
        -83.33506827580104,
        -15.196783572386307
    ],
    [
        -28.73766166140023,
        -85.26206131082516,
        -22.69326383503725
    ],
    [
        28.962326457134687,
        -80.70427267452177,
        -15.487538221037765
    ],
    [
        16.299144252830366,
        -80.46103640810749,
        -17.63276906963982
    ],
    [
        7.5389388121151795,
        -79.45129045696224,
        -21.559183628791807
    ],
    [
        -0.49837808926752203,
        -80.1718551782069,
        -23.40230675719635
    ],
    [
        -11.109342173771209,
        -80.13184320655972,
        -14.478677009065935
    ],
    [
        -18.67699725852295,
        -81.02292788640035,
        -18.358547798673875
    ],
    [
        -28.253463302684196,
        -80.06032143506201,
        -24.519643187414186
    ]
]

let kiwiRot = [
    [
        0.6721849764518423,
        0.9968008369815367,
        0.3069684293467015
    ],
    [
        1.1993080585759,
        0.8041738417257694,
        1.3594175599393612
    ],
    [
        1.1449097131973625,
        0.23183752219501158,
        0.1247238626213565
    ],
    [
        0.6711607107151015,
        1.31469240750071,
        0.48362189067315486
    ],
    [
        0.41173069654563443,
        0.38872165293642436,
        1.4380026109795279
    ],
    [
        0.7239929151064083,
        0.358791711617303,
        1.0084897383793565
    ],
    [
        0.9666545796323579,
        1.1725764938792218,
        0.7779030345230514
    ],
    [
        0.3017703756975062,
        0.6224281469979075,
        0.17640129004291916
    ],
    [
        0.22379244112960367,
        0.6669451821674659,
        0.2413460801771473
    ],
    [
        1.1590528095387702,
        0.26331848678354586,
        0.36710708383620966
    ],
    [
        0.4767066027352394,
        1.32735024181022,
        0.4743979484279273
    ],
    [
        0.46962925298398506,
        0.738987275873019,
        1.1662694785152905
    ],
    [
        0.5683681525056783,
        0.700606713826351,
        1.4685714046490732
    ],
    [
        0.370026923809757,
        1.2665624802741666,
        0.32032761589104136
    ],
    [
        0.32326884138451195,
        0.9806323259850424,
        0.8528661626703392
    ],
    [
        0.6561902026524632,
        1.3609584151974088,
        0.1156027636027593
    ],
    [
        1.1383427993082103,
        1.165722107653584,
        0.47420965369102386
    ],
    [
        0.1576282031217272,
        0.06286644740674077,
        1.5631921351588383
    ],
    [
        0.20696813606592696,
        0.271567135454432,
        1.315948386962167
    ],
    [
        1.1105297228503705,
        0.6243088030887474,
        0.6710013841201977
    ],
    [
        0.7897682866293132,
        1.3320837653202402,
        1.2803931910844137
    ],
    [
        0.9850907125421103,
        1.117447756032029,
        1.2349940145814269
    ],
    [
        0.8477182333224728,
        1.5479793459852509,
        0.2844945158404532
    ],
    [
        1.5046167889220303,
        1.5251189842996502,
        1.2659224698395224
    ],
    [
        1.2586670803620472,
        0.02230473968833188,
        1.1935578402877998
    ],
    [
        0.8009705604427345,
        0.1034549763252401,
        1.3489176318661598
    ],
    [
        0.14832523458129315,
        1.4225820073868523,
        0.13115833775261168
    ],
    [
        1.0154349525787152,
        1.1559974308126215,
        0.47782402948033026
    ],
    [
        0.7949776509720418,
        1.1449981079986822,
        0.6610029041632853
    ],
    [
        0.49590374172984547,
        0.6687855864141998,
        0.4252642418764066
    ],
    [
        1.3873218561929643,
        0.09517117543895086,
        1.0260367727792643
    ],
    [
        0.7319998268956581,
        1.2138704546633825,
        0.44197034592842566
    ],
    [
        1.3586492413873388,
        0.7451980762913044,
        0.7983611902243017
    ],
    [
        0.11088740612625674,
        0.016300893976750948,
        0.8198302614239397
    ],
    [
        1.203921280318687,
        1.5274632829681885,
        0.6633583662391517
    ]
]

export {kiwiPos, kiwiRot}