import {Gltf } from '@react-three/drei'
import { useThree } from '@react-three/fiber';


import red from '../../../../../model/Can/redAr.glb'
import green from '../../../../../model/Can/greenAr.glb'
import yellow from '../../../../../model/Can/yellowAr.glb'
import { useGesture } from '@use-gesture/react';
let stoper = false
let copyCan;
let arrPositionY = [-32.5, -62.5, -92.5, -122.5];
const rad = Math.PI / 180;

function nonToneMaping(scene, getCan) {
    let red = scene.getObjectByName('red');
    let yellow = scene.getObjectByName('yellow');
    let green = scene.getObjectByName('green');

    copyCan = [red, yellow, green]

    let backgroundRed = [scene.getObjectByName('groupTasteBackground0'), scene.getObjectByName('groupTasteBackground3')];
    let backgroundYellow = [scene.getObjectByName('groupTasteBackground1'), scene.getObjectByName('groupTasteBackground4')];
    let backgroundGreen = [scene.getObjectByName('groupTasteBackground2'), scene.getObjectByName('groupTasteBackground5')];
    
    if (red !== undefined && !stoper) {
        
        stoper = true;
        red.children[1].material.toneMapped = false
        yellow.children[1].material.toneMapped = false
        green.children[1].material.toneMapped = false
        console.log(red.children[1].material.toneMapped);
        getCan([red, yellow, green, backgroundRed, backgroundYellow, backgroundGreen])
    }
}
function Can({getCan, swiperMoved, dinamicOption}) {
    const canControl = useGesture({
        onHover: ({active, args: [position]}) => {
            
            let  divCanvas = document.getElementsByTagName('canvas')[0];
            if (dinamicOption.position === position || dinamicOption.position === 4 || !Number.isInteger(dinamicOption.position)) {
                if (active && !divCanvas.classList.contains('cursorPoinder')) {
                    divCanvas.classList.toggle('cursorPoinder');
                } else if(divCanvas.classList.contains('cursorPoinder')) {
                    
                    divCanvas.classList.toggle('cursorPoinder');
                }
            }
           
        },
        onDrag: ({ _delta:[_x, _y], args: [can],}) =>{  
            let moveCan = (can) => {
                let canObj = copyCan[can-1];
                let step = canObj.position.y - _y * .01
                let max = 6
                let min = -3
                if (dinamicOption.position < 4) {
                    if (step < arrPositionY[can-7] + min) {
                        step = arrPositionY[can-7] + min;
                    }
                    if (step > arrPositionY[can-7] + max) {
                        step = arrPositionY[can-7] + max;
                    }
                } else {
                    if (step < arrPositionY[3] + min) {
                        step = arrPositionY[3] + min;
                    }
                    if (step > arrPositionY[3] + max) {
                        step = arrPositionY[3] + max;
                    }
                }
                
                canObj.position.y = step;
                canObj.rotation.y += _x * rad;
            }
            //console.log(scene.getObjectByName('groupTasteBackground1'))
            if (!Number.isInteger(dinamicOption.position)) {
                switch (can) {
                    case 1:
                        moveCan(1);
                        break;
                    case 2:
                        moveCan(2);
                        break;
                    case 3:
                        moveCan(3);
                        break;
                    default:
                        break;
                }                    
            }
        },
        onWheel: ({ direction: [x,y], args: [can]}) => {
            let zoomCan = (can) => {
                let canObj = copyCan[can -1]
                let step = canObj.position.z + .5 * y;
                if (step < 2.4) {
                    step = 2.4
                }
                if (step > 15) {
                    step = 15
                }
                canObj.position.z = step;
            }
            if (!Number.isInteger(dinamicOption.position)) {
                zoomCan(can);
            }
        }
    })
    let {scene} = useThree();
    nonToneMaping(scene, getCan)
    return (<>
        <Gltf src={red}
            name='red'
            scale={[100, 100, 100]}
            position={[-7, -0.5, -5]}  
            rotation={[0, -0.65, 0]}
            {...canControl(1)}
            onClick={() => swiperMoved[0]('taste', 0.1, swiperMoved[1])}
        />
        <Gltf src={yellow}
            name='yellow'
            scale={[100, 100, 100]}
            position={[0, -0.5, 0]}  
            rotation={[0, -0.65, 0]}
            {...canControl(2)}
            onClick={() => swiperMoved[0]('taste', 0.2, swiperMoved[1])}
        />
        <Gltf src={green}
            name='green'
            scale={[100, 100, 100]}
            position={[7, -0.5, -5]}  
            rotation={[0, -0.65, 0]}
            {...canControl(3)}
            onClick={() => swiperMoved[0]('taste', 0.3, swiperMoved[1])}
        />
    </>);
}

export default Can;