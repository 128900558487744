import {useGLTF, Clone, Gltf} from '@react-three/drei'
import mango from '../../../../../model/mango.glb'
//6.2 minute
function Mango() {
    const {nodes, materials } = useGLTF(mango)
    return (<>
        <mesh castShadow receiveShadow 
            geometry={nodes.geo1_Material_1_0_3.geometry}
            material={materials['Material_1']} 
            position={[-23,-67,-19]} 
            scale={[100, 100, 100]} 
            rotation={[0, -Math.PI/3, -Math.PI/3]} 
            name='mango0'
        />
        <mesh castShadow receiveShadow 
            geometry={nodes.geo1_Material_1_0_3.geometry}
            material={materials['Material_1']} 
            position={[-18,-59,-18]} 
            scale={[100, 100, 100]} 
            rotation={[0, 0, -Math.PI/3]} 
            name='mango1'
        />
        <mesh castShadow receiveShadow 
            geometry={nodes.geo1_Material_1_0_3.geometry}
            material={materials['Material_1']} 
            position={[10,-54,-18]} 
            scale={[100, 100, 100]} 
            rotation={[-0.5, 0, -Math.PI/3*2]}
            name='mango2'
        />
        <mesh castShadow receiveShadow 
            geometry={nodes.geo1_Material_1_0_3.geometry}
            material={materials['Material_1']}   
            position={[25,-67,-18]} 
            scale={[100, 100, 100]} 
            rotation={[-0.5, 0, Math.PI/4]}
            name='mango3'  
        />
    </>);
}

export default Mango;