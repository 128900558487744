let strPos = [
    [
        28.45358927547424,
        -34.91263663357656,
        -18.514779648479575
    ],
    [
        15.1910722020123,
        -35.849390746972674,
        -21.091909530972856
    ],
    [
        9.43708871943783,
        -35.24126316532091,
        -17.28588369703747
    ],
    [
        3.429733935463426,
        -33.330561303977596,
        -23.212598221317794
    ],
    [
        -8.017011913266652,
        -33.74707837940794,
        -23.599794217798376
    ],
    [
        -20.03031305015908,
        -35.89190254519521,
        -20.677068181531254
    ],
    [
        -21.655584973776474,
        -35.128398387559685,
        -15.489525570490741
    ],
    [
        28.583423843166532,
        -29.12924593081496,
        -23.905962255189554
    ],
    [
        16.692453752773737,
        -30.545296715919314,
        -15.944228204694413
    ],
    [
        6.191197417118488,
        -31.688477619108827,
        -15.495928831409575
    ],
    [
        -0.41346185382042666,
        -31.86110104248766,
        -19.730708068116755
    ],
    [
        -5.92275319414086,
        -31.162077040059955,
        -16.98025436837045
    ],
    [
        -16.257004104400867,
        -29.371226246739226,
        -21.35692775780904
    ],
    [
        -28.603312916553453,
        -32.46278966220218,
        -21.82143943978128
    ],
    [
        24.50029880576234,
        -27.929786992916554,
        -24.692560383319034
    ],
    [
        17.490626349842678,
        -26.556107739334912,
        -17.691992937641427
    ],
    [
        7.247434375737173,
        -27.831759259351237,
        -20.553682663579714
    ],
    [
        -0.768996778633384,
        -23.92887801397021,
        -22.224930284666506
    ],
    [
        -6.6675097765798865,
        -24.101833277548735,
        -19.10479171381153
    ],
    [
        -13.18758399436274,
        -26.038349114457638,
        -14.69357184259184
    ],
    [
        -23.54516913752013,
        -26.117504659377953,
        -22.40147837882923
    ],
    [
        26.293562944895157,
        -21.045791841684775,
        -24.300095251529832
    ],
    [
        14.173992071028591,
        -19.468590108077315,
        -21.215328173643407
    ],
    [
        4.427308422674033,
        -21.484260444529642,
        -24.10868769358296
    ],
    [
        -1.2446109095532334,
        -21.624947504243707,
        -22.485812941327236
    ],
    [
        -10.764949840680103,
        -22.452318372762072,
        -20.44858957720986
    ],
    [
        -17.203031144828074,
        -20.19483585108432,
        -23.03462750903881
    ],
    [
        -22.944163615737224,
        -20.514947677182185,
        -21.549243051611317
    ],
    [
        29.82675144421134,
        -15.921523663172843,
        -18.374502340117175
    ],
    [
        15.061476104908138,
        -15.027703328846393,
        -18.84549523429316
    ],
    [
        5.582919124679665,
        -17.828750502130042,
        -19.587288141950893
    ],
    [
        1.2184351441449168,
        -15.349853290081008,
        -18.29135025800064
    ],
    [
        -11.002006722366197,
        -17.93588922619973,
        -24.734602131210597
    ],
    [
        -18.821175226563795,
        -18.858800386035835,
        -18.06613558340065
    ],
    [
        -24.202513321566233,
        -16.978308132411616,
        -14.825583792958415
    ]
]

let strRot = [
    [
        1.041292500537085,
        1.2787360722597485,
        0.5567442339650521
    ],
    [
        0.6501570177179495,
        0.0764938559484636,
        1.5301783455317055
    ],
    [
        1.025588142816029,
        0.4438349011027233,
        0.4379342233848448
    ],
    [
        1.2610316753845665,
        1.2683263183597109,
        0.2567646715328345
    ],
    [
        0.9984994500193123,
        1.015350750638515,
        0.5504755912486385
    ],
    [
        0.6563769914641706,
        0.015394869830937654,
        0.8507443754241766
    ],
    [
        0.8618633683413661,
        1.2806688228163585,
        0.11125879291391975
    ],
    [
        0.14243846392100926,
        0.48662530472602517,
        0.7300593536367929
    ],
    [
        0.6091582968029015,
        0.44502332603913863,
        0.8101998326775612
    ],
    [
        0.004758094884542548,
        0.0455364694015926,
        0.8965633813510832
    ],
    [
        0.44433987354011745,
        0.8474511171323533,
        1.3714507516318086
    ],
    [
        1.0549198726233482,
        1.3584661586360984,
        1.3354466884219967
    ],
    [
        1.5414643893321516,
        0.33807715621835405,
        0.12038769494941057
    ],
    [
        1.0816605605390561,
        0.2580077838535619,
        0.3751880045031227
    ],
    [
        0.5023184776905036,
        1.0332714439240922,
        1.1738554431499164
    ],
    [
        0.7676440086827814,
        0.12149097291846109,
        0.14020952709667844
    ],
    [
        0.7824508030157354,
        1.4115250407741249,
        1.1502801993899137
    ],
    [
        1.3617473272114244,
        1.0112916696248184,
        1.4231144649467569
    ],
    [
        0.8928513386875315,
        0.0547211064010477,
        0.2203602591224231
    ],
    [
        1.3064597681967236,
        0.8724655622644649,
        0.59608811074864
    ],
    [
        0.9667069116004339,
        0.46016482223592536,
        0.11058083468221318
    ],
    [
        0.36170853917275664,
        1.3434915215360614,
        1.4801274388435726
    ],
    [
        1.4057917610226403,
        1.2145651427319184,
        1.283830867754374
    ],
    [
        0.519862901269677,
        0.7002515928627915,
        0.5034684215709488
    ],
    [
        0.9362134285768755,
        1.1131543730077489,
        1.5150559844722744
    ],
    [
        0.18388730050558488,
        0.5584862619164163,
        0.4984076401490794
    ],
    [
        0.008161067827295124,
        0.8098118428728283,
        1.052508028540011
    ],
    [
        0.201216786071825,
        1.453711874668542,
        1.1451322868786835
    ],
    [
        0.9648686354865577,
        0.665788187378878,
        1.2630191922450453
    ],
    [
        0.9432367551092334,
        0.058067712617762675,
        0.8125439459317294
    ],
    [
        0.5816999953442759,
        0.07319376299916129,
        0.9864531904116645
    ],
    [
        0.8777349255532009,
        1.1719623841503621,
        0.43926368780076447
    ],
    [
        1.4382323968590491,
        0.3140213105710492,
        0.9884585837357458
    ],
    [
        0.9857512067032105,
        0.31346110916391967,
        0.27547721101730316
    ],
    [
        0.995488883575837,
        1.001764342520579,
        0.28537925924920643
    ]
]
export {strPos, strRot}