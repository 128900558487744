import Pc from './pc'
import Mobi from './mobi'
import logoWite from '../../../img/logo/logoWight.png'
import '../../../css/header.css'

function MainHeader({lang, changeLang, swiper}) {

    return (
        <header>
            <div id="pc-header">
                <Pc logo={logoWite} lang={lang} changeLang={changeLang} swiper={swiper}/>
            </div>
            <div id="mobi-header">
                <Mobi logo={logoWite} lang={lang} changeLang={changeLang} swiper={swiper}/>
            </div>
        </header>
    );
}

export default MainHeader;