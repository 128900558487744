import menu from '../../../img/logo/menu.png';

function Mobi({logo, lang, changeLang, swiper}) {   
    return (
        <>
            <div id="header">
                <div id="visMobi">
                    <img src={menu} alt="" className="leftMenu borderImg"></img>
                    <img src={logo} alt="" onClick={() => console.log(1)}></img>
                    <img src={menu} alt="" className="rightMenu borderImg"
                        onClick={()=>{document.getElementById("hidenMobi").classList.toggle('openMobi')}}></img>
                </div>
                <div id="hidenMobi">
                    <div className="rev">
                        <div onClick={() => swiper[0]('navigation', 0, swiper[1])}>
                            {lang[0]}
                        </div>
                    </div>
                    <div>
                        <div onClick={() => swiper[0]('navigation', 4, swiper[1])}>
                            {lang[1]}
                        </div>
                    </div>
                    <div className="rev">
                        <div onClick={() => swiper[0]('navigation', 5, swiper[1])}>
                            {lang[2]}
                        </div>
                    </div>
                    <div>
                        <div onClick={() => swiper[0]('navigation', 6, swiper[1])}>
                            {lang[3]}
                        </div>
                    </div>
                    <div className="rev">
                        <img src={lang[4]} alt="" onClick={() => {changeLang(lang[4])}}></img>
                        <img src={lang[5]} alt="" onClick={() => {changeLang(lang[5])}}></img>
                        <img src={lang[6]} alt="" onClick={() => {changeLang(lang[6])}}></img>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Mobi;