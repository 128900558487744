import { Gltf, PlaneGeometry } from "@react-three/drei";
import React from "react";

import seaFloor from '../../../../../model/seafloor.glb'

function WallWater() {
    return ( 
        <>
            <mesh position={[0, -75.75, -10]}>
                <planeGeometry attach="geometry" args={[1000, 151.5]} />
                <meshPhongMaterial attach="material" color={0x00bfff} 
                    transparent
                    opacity={0.3}
                />
            </mesh>
            <mesh position={[0, -152.2, 2.5]} rotation={[-Math.PI/2, 0, 0]}>
                <planeGeometry attach="geometry" args={[100, 30]} 
                    />
                <meshPhongMaterial attach="material" color={0x00bfff} 
                    transparent
                    opacity={0.3}
                />
            </mesh>
            <Gltf src={seaFloor}  
                castShadow 
                position={[0, -158, -41]} 
                scale={[200, 200, 500]} 
                rotation={[0, -1.5, 0]} 
            />
        </>
    );
}

export default WallWater;